import * as ActionTypes from './../ActionTypes';


const INITIAL_STATE = {
    hasUnsubscribed: false
};

const ReportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.EMAIL_GENERAL_STATE:
            state[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default ReportReducer;