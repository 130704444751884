import * as ActionTypes from './../ActionTypes';
import { sendGet, sendPost } from "../../service/request";
import { openNotificationWithIcon } from "../../utils/notification";

export const feedbackInputChange = ({ props, value }) => {
    return (dispatch) => {
        dispatch(feedbackGenerateState({ props, value, modal: 'payload' }));
    }
};


export const feedbackGenerateState = ({ props, value, modal = false }) => {
    return { type: ActionTypes.FEEDBACK_GENERAL_STATE, payload: { props, value, modal } }
};

export const postFeedback = () => {
    return (dispatch, getState) => {
        const { payload } = getState().feedback;
        dispatch(feedbackGenerateState({ props: 'saving', value: true }));
        sendPost('/feedback', payload).then(({ data }) => {
            openNotificationWithIcon('success', {
                title: 'Post Feedback',
                description: 'Thank you!! Feedback was sent successfully.'
            });
            dispatch(feedbackGenerateState({ props: 'feedbackModal', value: false }));
            setTimeout(function () {
                dispatch(feedbackGenerateState({ props: 'feedbackType', value: '' }));
                dispatch(feedbackInputChange({ props: 'rating', value: 0 }));
                dispatch(feedbackInputChange({ props: 'feed_type', value: '' }));
                dispatch(feedbackInputChange({ props: 'email', value: '' }));
                dispatch(feedbackInputChange({ props: 'description', value: '' }));
            }, 300);

            //if the url has ?feed_back_modal=true then remove it from the url
            window.history.replaceState(null, '', window.location.pathname);
        }).catch(err => {
        }).finally(() => {
            dispatch(feedbackGenerateState({ props: 'saving', value: false }));
        });
    };
};