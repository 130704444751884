import * as ActionTypes from '../ActionTypes';


const INITIAL_STATE = {
    data: [],
    fetching: false,
    pagination: {},
    item: {},
    relatedBlogs: []
};

const JobReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.BLOG_GENERAL_STATE:
            if (!action.payload.deep) {
                state[action.payload.props] = action.payload.value;
            } else {
                state[action.payload.props][action.payload.deep] = action.payload.value;
            }
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default JobReducer;