import * as ActionTypes from "./../ActionTypes";
import { sendPost, sendPut } from "../../../src/service/request";
import { openNotificationWithIcon } from "../../utils/notification";
import { redirect } from "../../utils/redirect";
import { setCookie } from "../../utils/session";
import { authenticatedUser, settingInputChange, userDetails } from "./UserActions";
import { Modal, Button } from 'antd';
import { getUrlParameter } from "../../utils/helpers";

const { confirm } = Modal;

export const authInputChange = ({ props, value, type = "signIn" }) => {
    return {
        type: ActionTypes.AUTH_INPUT_CHANGE,
        payload: { props, value, type }
    };
};


export const incorrectEmailStateChange = ({ props, value }) => {
    return {
        type: ActionTypes.AUTH_INCORRECT_STATE_CHANGE,
        payload: { props, value }
    };
};


export const authModelSwitch = (payload) => {
    return {
        type: ActionTypes.AUTH_MODEL_SWITCH,
        payload
    };
};


var globalInterval = null;


export const getVerificationLink = (email, isResetting = false) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_GENERAL_STATE,
            payload: { props: 'verifyLoader', value: true }
        });
        sendPost("/auth/verification-link", { email, isResetting })
            .then(response => {
                if (response.data.status === true) {
                    dispatch({
                        type: ActionTypes.AUTH_GENERAL_STATE,
                        payload: { props: 'verificationLinkSent', value: true }
                    });
                    dispatch(emailNotVerifiedModal(false));

                    openNotificationWithIcon('success', {
                        title: 'Link sent',
                        description: `Verification link was sent ${email}. Please check your inbox`
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 403) {
                    dispatch({
                        type: ActionTypes.AUTH_GENERAL_STATE,
                        payload: { props: 'retryInterval', value: err.response.data.time }
                    });
                    //not allowed due to time.
                    globalInterval = setInterval(function () {
                        dispatch(startCountDown(err.response.data.time));
                    }, 1000);
                }
            }).finally(() => {
                dispatch({
                    type: ActionTypes.AUTH_GENERAL_STATE,
                    payload: { props: 'verifyLoader', value: false }
                });
            });

    };
};

export const startCountDown = (time) => {
    return (dispatch, getState) => {
        if (getState().auth.retryInterval !== null && getState().auth.retryInterval !== 0) {
            time = getState().auth.retryInterval - 1;
        }
        else if (getState().auth.retryInterval === 0) {
            time = null;
            clearInterval(globalInterval);
        }

        dispatch({
            type: ActionTypes.AUTH_GENERAL_STATE,
            payload: { props: 'retryInterval', value: time }
        });
    };
};

export const signUp = () => {
    return (dispatch, getState) => {
        const { signUp } = getState().auth;
        let {
            email, password, password_confirmation, is_company_account, full_name
        } = signUp;
        dispatch({
            type: ActionTypes.AUTH_LOADER,
            payload: { type: "signUp", value: true }
        });
        //password_confirmation,
        is_company_account = !!is_company_account;

        const additionalPayload = {};
        if (getUrlParameter('type') === 'force_admin' && getUrlParameter('token')) {
            additionalPayload.auth_type = 'force_admin';
            additionalPayload.auth_token = getUrlParameter('token');
        }

        return sendPost("/auth/register", {
            email,
            password,
            is_company_account,
            full_name,
            ...additionalPayload
        })
            .then(response => {
                if (response.data.status === true)
                    dispatch({
                        type: ActionTypes.AUTH_SUCCESSFUL,
                        payload: { type: "signUp", encETk: response.data.user.encETk }
                    });
            })
            .catch(err => {
                if (err.response && err.response.status === 400) {
                    //validation errors
                    dispatch({
                        type: ActionTypes.AUTH_V_ERRORS,
                        payload: { type: "signUp", errors: err.response.data }
                    });
                }
            }).finally(() => {
                dispatch({
                    type: ActionTypes.AUTH_LOADER,
                    payload: { type: "signUp", value: false }
                });
            });
    };
};

export const updateCorrectEmail = () => {
    return (dispatch, getState) => {
        const { signUp } = getState().auth;
        let {
            correctEmail,
            emailToken
        } = signUp.incorrectEmail;
        dispatch({
            type: ActionTypes.AUTH_INCORRECT_STATE_CHANGE,
            payload: { props: "loader", value: true }
        });
        //password_confirmation,

        sendPut("/auth/email/correction", { email: correctEmail, encETk: emailToken, full_name: signUp.full_name })
            .then(response => {
                if (response.data.status === true) {
                    dispatch({
                        type: ActionTypes.AUTH_SUCCESSFUL,
                        payload: { type: "signUp", encETk: response.data.user.encETk }
                    });
                    dispatch({
                        type: ActionTypes.AUTH_INCORRECT_STATE_CHANGE,
                        payload: { props: "correctModal", value: false }
                    });
                    dispatch({
                        type: ActionTypes.AUTH_INCORRECT_STATE_CHANGE,
                        payload: { props: "correctEmail", value: '' }
                    });

                    dispatch(authInputChange({ props: 'email', value: correctEmail, type: 'signUp' }));

                    openNotificationWithIcon('success', {
                        title: 'Update Email',
                        description: 'Your email address was updated successfully'
                    });
                }

            })
            .catch(err => {
                if (err.response && err.response.status === 400) {

                }
            }).finally(() => {
                dispatch({
                    type: ActionTypes.AUTH_INCORRECT_STATE_CHANGE,
                    payload: { props: "loader", value: false }
                });
            });
    };
};


export const emailNotVerifiedModal = (value) => {
    return {
        type: ActionTypes.AUTH_GENERAL_STATE,
        payload: { type: 'unverifiedAttempt', props: 'signIn', value }
    };
};


export const signIn = (redirectToSuccess = false) => {
    return (dispatch, getState) => {
        const { signIn, afterLoginSuccess } = getState().auth;
        const { email, password } = signIn;
        dispatch({
            type: ActionTypes.AUTH_LOADER,
            payload: { type: "signIn", value: true }
        });
        sendPost("/auth/login", {
            email: email.trim(),
            password: password.trim(),
        })
            .then(({ data }) => {
                if (data.status === true && data.token) {
                    dispatch({
                        type: ActionTypes.AUTH_SUCCESSFUL,
                        payload: { type: "signIn" }
                    });
                    setCookie('rs_au', data.token);
                    dispatch(authenticatedUser({}));

                    if (redirectToSuccess) {
                        data.ica === true ? redirect('/company') : redirect('/user');
                    }
                    if (afterLoginSuccess && typeof afterLoginSuccess === 'function') {
                        afterLoginSuccess();
                        dispatch({
                            type: ActionTypes.AUTH_GENERAL_STATE, payload: { props: 'afterLoginSuccess', value: null }
                        });
                    } else if (/FETCH_USER_DETAILS\-.*/.test(afterLoginSuccess) === true) {
                        const userId = afterLoginSuccess.split('-')[1];
                        dispatch(userDetails(userId, {}, false));
                    }

                    return true;
                }
                return false;
            })
            .catch(err => {
                dispatch({
                    type: ActionTypes.AUTH_LOADER,
                    payload: { type: "signIn", value: false }
                });
                if (err.response)
                    switch (err.response.status) {
                        case 400: {
                            dispatch({
                                type: ActionTypes.AUTH_V_ERRORS,
                                payload: { type: "signIn", errors: err.response.data }
                            });
                            err.response.data.map(function (item) {
                                if (item.context.emailNotVerified) {
                                    dispatch(emailNotVerifiedModal(true));
                                }
                            });
                        }
                            break;
                        case 401:
                            // dispatch({
                            //     type: ActionTypes.AUTH_V_ERRORS,
                            //     payload: {
                            //         type: "signIn",
                            //         errors: {email: ["Invalid email or password"]}
                            //     }
                            // });
                            break;
                    }
            }).finally(() => {

            });
    };
};


export const authScreenSwitch = () => {
    return {
        type: ActionTypes.AUTH_SCREEN_SWITCH
    }
};


export const socialAuthentication = (provider, data) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_LOADER,
            payload: { type: "signIn", value: true }
        });
        let payload = {};
        if (provider === 'facebook') {
            payload.provider = provider;
            payload.access_token = data;
        } else {
            payload.provider = 'google';
            payload.googleUser = data;
        }

        return sendPost('/auth/login/social', payload).then(({ data }) => {
            dispatch({
                type: ActionTypes.AUTH_SUCCESSFUL,
                payload: { type: "signIn" }
            });
            setCookie('rs_au', data.token);
            dispatch(authenticatedUser({}));
            redirect('/user')

        }).catch(() => {
            dispatch({
                type: ActionTypes.AUTH_LOADER,
                payload: { type: "signIn", value: false }
            });
        })
    }
};

/**
 * Request for reset link
 * @returns {function(*, *): Promise<T | never>}
 */
export const resetPassword = () => {
    return (dispatch, getState) => {
        const { reset } = getState().auth;
        const { email } = reset;

        dispatch({ type: ActionTypes.AUTH_LOADER, payload: { type: 'reset', value: true } });

        return sendPost('/auth/reset', { email }).then(({ data }) => {
            if (data.status === true) {
                dispatch(resetLinkTryAgain(true));
            }
            dispatch({ type: ActionTypes.AUTH_LOADER, payload: { type: 'reset', value: false } });
        }).catch(err => {
            if (err.response)
                switch (err.response.status) {
                    case 422: {
                        dispatch({
                            type: ActionTypes.AUTH_V_ERRORS,
                            payload: { type: 'reset', errors: err.response.data.errors }
                        });
                        break;
                    }
                }
            dispatch({ type: ActionTypes.AUTH_LOADER, payload: { type: 'reset', value: false } });
        });
    }
};


/**
 * Reset new password after the token is matched
 * @returns {Function}
 */
export const updateNewPassword = () => {
    return (dispatch, getState) => {
        const { reset } = getState().auth;
        const { password, password_confirmation } = reset;
        const token = location.search.replace('?token=', '');

        if (password.length && password_confirmation.length && token.length) {
            dispatch({ type: ActionTypes.AUTH_LOADER, payload: { type: 'reset', value: true } });
            return sendPost('/auth/reset/update', { password_confirmation, password, token }).then(({ data }) => {
                if (data.status === true) {
                    openNotificationWithIcon('success', {
                        title: 'Password updated',
                        description: 'You now have a new password to login to your account.'
                    });
                    setCookie('rs_au', data.token);
                    dispatch(authenticatedUser({}));
                    data.ica === true ? redirect('/company') : redirect('/user');

                } else {

                }
                dispatch({
                    type: ActionTypes.AUTH_V_ERRORS,
                    payload: { type: 'reset', errors: [] }
                });
            }).catch(err => {
                if (err.response)
                    switch (err.response.status) {
                        case 400: {
                            //invalid
                            dispatch({
                                type: ActionTypes.AUTH_V_ERRORS,
                                payload: { type: 'reset', errors: err.response.data }
                            });
                            break;
                        }
                        case 403: {
                            //forbidden
                            openNotificationWithIcon('error', {
                                title: 'Failed',
                                description: err.response.data.msg || 'Forbidden'
                            });
                            dispatch({
                                type: ActionTypes.AUTH_V_ERRORS,
                                payload: { type: 'reset', errors: [] }
                            });
                        }
                    }
            }).finally(() => {
                dispatch({ type: ActionTypes.AUTH_LOADER, payload: { type: 'reset', value: false } });
            });
        }
    }
};


export const rewriteOldPassword = () => {
    return (dispatch, getState) => {
        dispatch(authInputChange({
            props: 'loader',
            value: true,
            type: 'updatePassword'
        }));

        return sendPut('/auth/password', { ...getState().auth.updatePassword }, {}, true).then(() => {
            openNotificationWithIcon('success', {
                title: 'Password updated',
                description: 'Congratulation you now have a new password to log in.'
            });
            dispatch(settingInputChange({
                props: 'edit',
                value: false,
                type: 'password'
            }));
            dispatch(authenticatedUser());
            dispatch(authInputChange({
                props: 'new_password_confirmation',
                value: '',
                type: 'updatePassword'
            }));
            dispatch(authInputChange({
                props: 'new_password',
                value: '',
                type: 'updatePassword'
            }));
            dispatch(authInputChange({
                props: 'old_password',
                value: '',
                type: 'updatePassword'
            }));
        }).finally(() => {
            dispatch(authInputChange({
                props: 'loader',
                value: false,
                type: 'updatePassword'
            }));
        });
    }
};


export const updateLoginId = (email) => {
    return (dispatch) => {
        confirm({
            title: 'Update Email?',
            content: `You will receive a reset token on your new email address(${email}). Please verify your new email with  the received token.`,
            onOk() {
                return sendPut('/auth/login-id', { user: email }, {}, true).then(({ data }) => {
                    if (data.auth_token)
                        setCookie('rs_au', data.auth_token);
                    dispatch(settingInputChange({
                        props: 'edit',
                        value: false,
                        type: 'email'
                    }));

                    dispatch(settingInputChange({
                        props: 'updateWasSuccess',
                        value: true,
                        type: 'email'
                    }));
                    dispatch(authenticatedUser());
                })
            },
            onCancel() {
            },
        });

    }
};


export const resetLinkTryAgain = (payload) => {
    return {
        type: ActionTypes.AUTH_RESET_LINK_SUCCESS,
        payload
    }
};


export const verifyEmail = (token, ctx) => {
    return (dispatch) => {
        if (token.length) {
            const payload = { token };
            if (ctx.query && ctx.query.resetEmail) {
                payload.resetEmail = ctx.query.resetEmail;
            }

            return sendPost('/auth/verify', payload).then(({ data }) => {
                if (!data.status) {
                    dispatch({
                        type: ActionTypes.AUTH_VERIFICATION_STATE,
                        prop: 'email',
                        payload: { prop: 'email', value: false }
                    });
                } else {
                    dispatch({
                        type: ActionTypes.AUTH_VERIFICATION_STATE,
                        prop: 'email',
                        payload: { prop: 'email', value: true }
                    });

                    dispatch({
                        type: ActionTypes.AUTH_VERIFICATION_STATE,
                        prop: 'token',
                        payload: { prop: 'token', value: data.data.tk || '' }
                    });

                    dispatch({
                        type: ActionTypes.AUTH_VERIFICATION_STATE,
                        prop: 'token',
                        payload: { prop: 'ica', value: data.data.ica || '' }
                    });

                    if (ctx.query && ctx.query.resetEmail) {
                        openNotificationWithIcon('success', {
                            title: 'Email updated',
                            description: 'New email was updated successfully!!'
                        });
                        dispatch(settingInputChange({
                            props: 'updateWasSuccess',
                            value: false,
                            type: 'email'
                        }));
                        dispatch(authenticatedUser());
                    }
                }
            }).catch(err => {
                dispatch({
                    type: ActionTypes.AUTH_VERIFICATION_STATE,
                    prop: 'email',
                    payload: { prop: 'email', value: false }
                });

                switch (err.response.status) {
                    case 404:
                        openNotificationWithIcon('error', {
                            title: 'Failed',
                            description: err.response.data.msg
                        });
                        break;
                }


            });
        }
    }
};


export const resetAuthState = (payload = '') => {
    return {
        type: ActionTypes.AUTH_STATE_RESET,
        payload
    }
};

export const authGeneralStateChange = ({ props, value }) => {
    return {
        type: ActionTypes.AUTH_GENERAL_STATE,
        payload: { props, value }
    }
};

