import { sendDelete, sendGet, sendPut } from "../../../src/service/request";
import * as ActionTypes from "./../ActionTypes";
import { removeCookie } from "./../../utils/session";
import { redirect } from "../../utils/redirect";
import { openNotificationWithIcon } from "../../utils/notification";
import { isAuthenticated } from "../../utils/auth";

export const authenticatedUser = (ctx) => {
    return (dispatch) => {
        return sendGet('/user/state', {}, true, ctx).then(({ data }) => {
            if (data.status === true) {
                dispatch({ type: ActionTypes.USER_STATE, payload: { user: data.user } });
                if (data.company && Object.keys(data.company)) {
                    dispatch({ type: ActionTypes.COMPANY_STATE, payload: data.company })
                }
            }
            return data;
        })
    }
};


export const userInputChange = ({ props, value, type = "data" }) => {
    return {
        type: ActionTypes.USER_INPUT_CHANGE,
        payload: { props, value, type }
    };
};


export const submitPersonalResume = (saveContinue = false) => {
    return (dispatch, getState) => {
        const { updateCopy } = getState().resume;

        dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'personalLoading', value: true } });

        sendPut('/resume/personal', updateCopy.personal, {}, true).then(({ data }) => {
            if (data.status === true) {
                openNotificationWithIcon('success', {
                    title: 'Update Resume',
                    description: 'Personal information where updated successfully'
                });
                dispatch({ type: ActionTypes.RESUME_UPDATE_SUCCESS, payload: true, value: 'personal' });
                dispatch(fetchMyResume());
                if (saveContinue === true)
                    dispatch({
                        type: ActionTypes.USER_GENERATE_STATE,
                        payload: { props: 'defaultActiveTabKey', value: '2' }
                    });
            }
        }).catch(err => {
            if (err.response)
                dispatch({
                    type: ActionTypes.RESUME_GENERAL_STATE,
                    payload: { props: 'resumeValidationErrors', value: err.response.data.errors }
                });
        }).finally(() => {
            dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'personalLoading', value: false } });
        });
    }
};


export const fetchMyResume = (ctx) => {
    return (dispatch) => {
        return sendGet('/user/resume', {}, true, ctx).then(({ data }) => {
            if (data.status === true) {
                //update user state.
                dispatch({ type: ActionTypes.USER_STATE, payload: { user: data.resume.personal } });
                dispatch({
                    type: ActionTypes.RESUME_SINGLE_STATE,
                    payload: data.resume
                });
            }
        }).catch(err => {
        })
    }
};


export const userDetails = (userId, ctx, isApplicantDetails = false) => {
    return (dispatch, getState) => {
        const { selectedApplicant } = getState().job;
        if (!userId) return false;
        return new Promise((resolve, reject) => {
            return sendGet('/user/details/' + userId, {}, false, ctx).then(({ data }) => {
                //Is intruder is true when the user details page is viewed with out authentication
                //hence after the authentication trying to fetch the full user details again.
                if (data.isIntruder === true) {
                    // dispatch({
                    //     type: ActionTypes.AUTH_GENERAL_STATE, payload: {
                    //         props: 'afterLoginSuccess', value: () => {
                    //             dispatch(userDetails(userId, {}, isApplicantDetails));
                    //         }
                    //     }
                    // });
                    dispatch({
                        type: ActionTypes.AUTH_GENERAL_STATE, payload: {
                            props: 'afterLoginSuccess', value: `FETCH_USER_DETAILS-${userId}`
                        }
                    });
                }
                if (!isApplicantDetails) {
                    dispatch({ type: ActionTypes.USER_GENERATE_STATE, payload: { props: 'profilePage', value: data.resume } });

                    dispatch({
                        type: ActionTypes.USER_GENERATE_STATE,
                        payload: { props: 'isIntruder', value: data.isIntruder }
                    });
                }
                else {
                    data.resume.applicant = selectedApplicant;
                    dispatch({
                        type: ActionTypes.JOB_GENERAL_STATE,
                        payload: { props: 'selectedApplicant', value: data.resume }
                    });
                }

                resolve(data);
            }).catch(err => {
                reject(err);
            })
        });
    }
};

/**
 * with softRelation true if no related user found six recently profile updating user will be fetched.
 * @param {*} param0 
 */
export const relatedUsers = ({ userId, ctx, categoryId, softRelation = false }) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return sendGet(`/user/related/`, { userId, categoryId, softRelation }, false, ctx).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
};


export const publishResume = (publish) => {
    return (dispatch) => {
        publish = publish ? 1 : 0;
        return sendPut('/resume/publish', { publish }, {}, true).then((res) => {
            let title = 'Resume Published';
            let description = 'Your resume now can be discovered by companies';
            if (!publish) {
                title = 'Resume Unpublished';
                description = 'Your resume will now be hidden';
            }
            openNotificationWithIcon('success', {
                title,
                description
            });
            dispatch({
                type: ActionTypes.USER_RESUME_PUBLISH,
                payload: { value: publish }
            });
        })
    }
};


export const userSocialUpdate = (socialPayload) => {
    return (dispatch, getState) => {
        const { social } = getState().user;
        return sendPut('/user/social', socialPayload, {}, true).then(res => {
            openNotificationWithIcon('success', {
                title: 'Social updated',
                description: 'Social information were updated successfully.'
            });
            dispatch(authenticatedUser());
        });
    }
};


export const userSocialAddOrRemove = (props, remove = true) => {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_ADD_OR_REMOVE_SOCIAL,
            payload: { key: props, remove }
        });
    }
};


export const userFilterInputChange = ({ props, value, searchNow = true, limit, hits }) => {
    return (dispatch, getState) => {
        if (!limit) limit = 15;
        dispatch({
            type: ActionTypes.USER_FILTER,
            payload: { props, value, hits }
        });
        if (searchNow === true)
            dispatch(getAllEmployeeList({ ...getState().user.filter, limit }));
    };
};

/**
 * Is Infinite flag is used to append the new users on the current user's list
 * @param filter
 * @param isInfinite
 * @param isHomePage
 * @returns {function(*, *): Promise<T | never>}
 */
export const getAllUsers = (filter = {}, isInfinite = false, isHomePage = false) => {
    return (dispatch, getState) => {
        return sendGet('/user', filter).then(({ data }) => {
            if (isHomePage) {
                dispatch({
                    type: ActionTypes.USER_GENERATE_STATE,
                    payload: { props: 'homePageUserList', value: data.data }
                });
            } else {
                dispatch({ type: ActionTypes.USER_FETCH_ALL, payload: data.data });
            }
        }).catch(err => {
        })
    }
};

export const getAllEmployeeList = (query, isInfinite = false) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.USER_GENERATE_STATE, payload: { props: 'fetchingEmployeeList', value: true } });
        return sendGet('/user', query).then(({ data }) => {
            dispatch({ type: ActionTypes.USER_FETCH_ALL, payload: data.data, pagination: data.pagination, isInfinite });
            if (data.category)
                dispatch({
                    type: ActionTypes.USER_GENERATE_STATE,
                    payload: { props: 'selectedHits', value: [data.category] }
                });
        }).catch(err => {
        }).finally(() => {
            dispatch({ type: ActionTypes.USER_GENERATE_STATE, payload: { props: 'fetchingEmployeeList', value: false } });
        })
    }
};


export const userGenerateState = ({ props, value, deep }) => {
    return { type: ActionTypes.USER_GENERATE_STATE, payload: { props, value, deep } }
};


export const userSocialInputChange = ({ props, value }) => {
    return {
        type: ActionTypes.USER_SOCIAL_INPUT_STATE,
        payload: { props, value }
    }
};


export const logout = () => {
    return (dispatch) => {
        signOut();
        dispatch({ type: ActionTypes.USER_STATE_LOGOUT });
    }
};


export const signOut = (ctx = {}) => {
    if (process.browser) {
        removeCookie("rs_au");
        redirect("/auth/login", ctx);
    }
};


export const deleteUserImage = (type) => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            sendDelete(`/user/clear/image?type=${type}`, {}, {}, true).then((res) => {
                openNotificationWithIcon('success', {
                    title: 'Image Cleared',
                    description: 'A Profile with a image is always prioritised! :)'
                });
                type === 'company' ? dispatch({ type: ActionTypes.COMPANY_LOGO_CLEARED }) : dispatch({ type: ActionTypes.USER_LOGO_CLEARED });
                resolve(res);
            }).catch(error => {
                reject(error);
            });
        });
    }
};

export const settingInputChange = ({ props, type, value }) => {
    return {
        type: ActionTypes.USER_SETTINGS,
        payload: { props, type, value }
    }
};

export const updateSettings = (data) => {
    return (dispatch) => {
        return sendPut('/settings/user', data).then(({ data }) => {
            dispatch({
                type: ActionTypes.USER_GENERATE_STATE,
                payload: { value: data, props: 'emailSettingResponse' }
            });
        })
    }
};


export const openUserDetails = (href, as) => {
    return (dispatch) => {
        redirect(as, {}, href, true);
    }
};