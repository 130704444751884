import { sendGet } from "../../../src/service/request";
import * as ActionTypes from "./../ActionTypes";


export const getAllCategory = (ctx, type = '', withChild = false) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendGet('/category?type=' + type + '&with_child=' + withChild).then(({ data }) => {
                if (data.status === true) {
                    dispatch({ type: ActionTypes.CATEGORY_ALL_STATE, payload: data.data });
                }
                resolve(data);
            }).catch(err => {
                reject(err);
            })
        });
    }
};

export const getCategory = ({ id, withChild = false }) => {
    return () => {
        return new Promise((resolve, reject) => {
            return sendGet(`/category/${id}?with_child=` + withChild).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
};

