import * as ActionTypes from './../ActionTypes';


const INITIAL_STATE = {
    socketInstance: null,
    showCategorySideBar: false,
    showEmployeeFilter: false,
    showJobFilter: false,
    showBlogFilter: false,
    showCompanyFilter: false,
    searchModal: false,
    showAuthNav: false,
    validationErrors: {},
    //contacts
    contact: {
        full_name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    },

    savingContact: false,
    contactSuccess: false,
    //faq
    faqs: [],
    faqs_permanent: [],
    selectedFaqCategory: 'all'
};

const AppReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.APP_GENERAL_STATE:
            if (!action.payload.deep) {
                state[action.payload.props] = action.payload.value;
            }
            else
                state[action.payload.props][action.payload.deep] = action.payload.value;
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default AppReducer;