import * as ActionTypes from './../ActionTypes';
import {sendGet} from "../../service/request";


export const reportDashboard = (type = 'personal') => {
    return (dispatch, getState) => {
        return sendGet('/report/dashboard', {type}, true).then(({data}) => {
            dispatch({type: ActionTypes.FETCH_REPORT_DASHBOARD, payload: {value: data}});
        });
    }
};
