import * as ActionTypes from './../ActionTypes';

const INITIAL_STATE = {
    data: {
        email: '',
        fname: '',
        mname: '',
        lname: '',
        full_name: '',
        provider: '',
        phone: '',
        p_address: '',
        t_address: '',
        image: '',
        father_name: '',
        dob: '',
        nationality: '',
        gender: '',
        expertise_title: '',
        updated_at: '',
        ica: 0,
        irp: 0,
        social: {}
    },
    fetchingEmployeeList: false,
    itemList: [],
    homePageUserList: [],
    pagination: {},
    social: {},
    password: {
        old_password: '',
        password: '',
        password_confirmation: '',
    },
    savingState: false,
    errors: {},
    fieldsTouched: false,
    authCheck: false,
    profilePage: {},
    isIntruder: false,
    filter: {
        // category: null,
        sub_category: null,
        experience: '',
        address: '',
        search: '',
        academic: '',
        salary: '',
        availability: ''
    },
    selectedHits: [],
    settings: {
        email: {
            edit: false,
            value: '',
            emailCopy: '',
            updssateCopy: '',
            updateWasSuccess: false,
        },
        password: {
            edit: false,
        }
    },
    emailSettingResponse: {},
    defaultActiveTabKey: '1',
    related_users: []
};

const UserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.USER_STATE:
            state.data = action.payload.user;
            state.settings.email.value = action.payload.user.email;
            state.settings.email.updateCopy = action.payload.user.email;
            state.settings.email.emailCopy = action.payload.user.email;
            state.social = action.payload.user.social;

            state.authCheck = true;
            return {
                ...state
            };
        case ActionTypes.USER_FETCH_ALL:
            state.itemList = action.isInfinite ? [...state.itemList, ...action.payload] : action.payload;
            state.pagination = action.pagination || {};
            return {
                ...state
            };

        case ActionTypes.USER_LOGO_CLEARED:
            state.data.image = 'https://rojal.s3.ap-south-1.amazonaws.com/defaults/profile.png';
            return {
                ...state
            };
        case ActionTypes.USER_FILTER:
            if (action.payload.props === 'sub_category') {
                state.selectedHits = action.payload.hits;
            }

            state.filter[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.USER_STATE_LOGOUT:
            return INITIAL_STATE;
        case ActionTypes.USER_INPUT_CHANGE:
            state[action.payload.type][action.payload.props] = action.payload.value;
            state.fieldsTouched = true;
            return {
                ...state
            };
        case ActionTypes.USER_SOCIAL_INPUT_STATE:
            if (!state.social)
                state.social = {};
            state.social[action.payload.props] = action.payload.value;

            return {
                ...state
            };
        case ActionTypes.USER_ADD_OR_REMOVE_SOCIAL:
            if (state.social.hasOwnProperty(action.payload.key) && action.payload.remove) {
                delete state.social[action.payload.key];
            } else {
                state.social[action.payload.key] = '';
            }
            return {
                ...state
            };
        case ActionTypes.USER_GENERATE_STATE:
            if (!action.payload.deep) {
                state[action.payload.props] = action.payload.value;
            } else {
                state[action.payload.props][action.payload.deep] = action.payload.value;
            }
            return {
                ...state
            };
        case ActionTypes.USER_RESUME_PUBLISH:
            state['data'].irp = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.USER_SETTINGS:
            state.settings[action.payload.type][action.payload.props] = action.payload.value;
            return {
                ...state
            };
        default: {
            return {
                ...state
            }
        }
    }
};
export default UserReducer;
