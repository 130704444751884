import * as ActionTypes from './../ActionTypes';

const INITIAL_STATE = {
    data: {}
};

const CandidateReducer= (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.CANDIDATE_GENERAL_STATE:
            state[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        default: {
            return {
                ...state
            }
        }
    }
};
export default CandidateReducer;