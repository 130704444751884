import Router from "next/router";

export const redirect = (target, ctx = {}, as = null, reset = false) => {
    if (ctx.res) {
        // If on the server, an HTTP 303 response with a "Location"
        // is used to redirect.
        ctx.res.writeHead(303, {Location: target});
        ctx.res.end();
    } else {
        // On the browser, next/router is used to "replace" the current
        // location for the new one, removing it from history.
        // Router.replace(target);

        if (!as)
            Router.push(target).then(() => {
                if (reset) {
                    window.scrollTo(0, 0)
                }
            });
        else
            Router.push(target, as).then(() => {
                if (reset) {
                    window.scrollTo(0, 0)
                }
            });
    }
};