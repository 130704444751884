import { redirect } from "./redirect";
import { Icon, Modal } from "antd";

const { confirm } = Modal;

export const ucFirst = str => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const ucWords = str => {
    if (!str) return '';
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const currencyFormat = (amount) => {
    if (!amount) return false;

    const currency = Intl.NumberFormat('hi', { style: 'currency', currency: 'NPR' });
    return currency.format(amount);
};


export const xTimes = x => f => {
    if (x > 0) {
        f();
        xTimes(x - 1)(f)
    }
};

export const jobExperienceType = (key = '') => {
    const jobTypes = {
        0: 'Freshers',
        1: '6 Months to 1 Year',
        2: '1 to 3 Years',
        3: '3 to 5 Years',
        4: '5 to 7 Years',
        5: '7 to 10 Years',
        6: '10 Years & Above'
    };

    if (key.length) {
        return jobTypes[key];
    }
    return jobTypes;
};

export const academicQualificationHead = (key = '', withExtraFields = false) => {
    const headTypes = {
        unsee: 'Under Secondary',
        see: 'Secondary(SEE)/(SLC)',
        hsec: 'Higher Secondary (+2 ,Diploma, A Level )',
        voc: 'Vocational',
        bach: 'Bachelor',
        mast: 'Master',
        doct: 'Doctorate'
    };
    if (withExtraFields === true) {
        headTypes.any = 'Any (Not Required)'
    }

    if (key.length) {
        return headTypes[key];
    }
    return headTypes;
};


export const postedOnType = (key = '') => {
    const postedTypes = {
        last_hour: 'Last Hour',
        last_24_hour: 'Last 24 Hours',
        last_7_days: 'Last 7 Days',
        last_14_days: 'Last 14 Days',
        last_30_days: 'Last 30 Days'
    };

    if (key.length) {
        return postedTypes[key];
    }
    return postedTypes;
};


export const availabilityType = (key = '') => {
    // key=parseInt(key);
    const postedTypes = {
        0: 'Part Time',
        1: 'Full Time',
        2: 'Freelance'
    };

    if (key !== '') {
        key = parseInt(key);
        return postedTypes[key];
    }
    return postedTypes;
};

export const maritalStatus = (key = '') => {
    const postedTypes = {
        unmarried: 'Un Married',
        married: 'Married'
    };

    if (key !== '' && key != null) {
        // key = parseInt(key);
        return postedTypes[key];
    }
    return postedTypes;
};


export const socials = (key = '') => {
    const socialTypes = {
        facebook: 'Facebook',
        linkedin: 'LinkedIn',
        twitter: 'Twitter',
        instagram: 'Instagram',
        youtube: 'Youtube',
        whatsapp: 'WhatsApp',
        personal: 'MyWebsite',
        github: 'Github'
    };
    if (key) {
        return socialTypes[key];
    }
    return socialTypes;
};


export const salaryRange = (key = '') => {
    const ranges = {
        10: '0 to 10K',
        20: '10K to 20K',
        50: '20K to 50K',
        100: '50K to 100K',
        101: '100K and Above',
    };
    if (key) {
        return ranges[key];
    }
    return ranges;
};


export const hasChildCategory = (categoryList = [], id) => {
    let output = false;
    try {
        categoryList.forEach(function (item) {
            if (item.id === id && item.children.length > 0) {
                output = true;
                throw Error('Stop');
            }
        })
    } catch (e) {

    }

    return output;
};


export const dashboardNavigationItems = (isCompany = false) => {
    return isCompany ? [
        {
            name: 'Account Info',
            route: '/company',
            icon: 'far fa-user'
        },
        {
            name: 'Edit Profile',
            route: '/company/update-profile',
            icon: 'far fa-edit'
        },
        {
            name: 'Post Job Vacancy',
            route: '/company/post-job',
            icon: 'fas fa-user-tie'
        },
        {
            name: 'My Job Post',
            route: '/company/job-posts',
            icon: 'far fa-bell'
        },
        {
            name: 'My Favourite List',
            route: '/company/favourites',
            icon: 'far fa-heart'
        },
        {
            name: 'Account Settings',
            route: '/company/settings',
            icon: 'fas fa-cogs'
        },
        // {
        //     name: 'Archived Job Post',
        //     route: '/archived-job',
        //     icon: 'fas fa-dot-circle'
        // },
        {
            name: 'Logout',
            route: '/auth/logout',
            icon: 'fas fa-sign-out-alt'
        }] : [
        {
            name: 'Account Info',
            route: '/user',
            icon: 'fa fa-life-ring'
        },
        {
            name: 'My Resume',
            route: '/user/my-resume',
            icon: 'fas fa-user-circle'
        },
        {
            name: 'Edit Resume',
            route: '/user/update-resume',
            icon: 'fas fa-user-edit'
        },
        {
            name: 'Applied Jobs',
            route: '/user/applied-job',
            icon: 'fas fa-clipboard-check'
        },
        {
            name: 'My Favourite List',
            route: '/user/favourites',
            icon: 'fas fa-heart'
        },
        {
            name: 'Account Settings',
            route: '/user/settings',
            icon: 'fas fa-cogs'
        },
        // {
        //     name: 'Bookmarked Job',
        //     route: '',
        //     icon: 'far fa-bookmark'
        // },
        // {
        //     name: 'Archived Apply Job',
        //     route: '',
        //     icon: 'fa fa-clone'
        // },
        {
            name: 'Log out',
            route: '/auth/logout',
            icon: 'fas fa-sign-out-alt',
            // action: logout
        },
        // {
        //     name: 'Close Account',
        //     route: '',
        //     icon: 'fas fa-user-times'
        // }
    ];
};


/**
 * Calculates the user's resume percentage (user, resume_info, academics, experiences)
 * @param userInfoJson
 * @returns {number}
 */
export const profileCompletePercent = (userInfoJson) => {
    let percentageFieldsUser = ['dob', 'email', 'expertise_title', 'father_name', 'full_name', 'gender', 'marital_status', 'nationality', 'p_address', 'phone', 't_address', 'sub_category_id'];

    let percentageFieldsResumeInfo = ['availability', 'career_objectives', 'declaration', 'minimum_salary', 'special_qualifications', 'languages'];

    let percentageCount = 0;
    percentageFieldsUser.map(function (item) {
        if (userInfoJson.personal[item]) {
            percentageCount -= -5;
        }
    });

    percentageFieldsResumeInfo.map(function (item) {
        if (item === 'languages' || item === 'special_qualifications') {
            if (userInfoJson.resume_info[item] && userInfoJson.resume_info[item].length) {
                percentageCount -= -5;
            }
        } else if (userInfoJson.resume_info[item] && userInfoJson.resume_info[item]) {
            percentageCount -= -5;
        }
    });

    if (userInfoJson.academics.length) {
        percentageCount -= -5;
    }

    if (userInfoJson.experiences.length) {
        percentageCount -= -5;
    }

    return percentageCount;
};

export const isReadyForPublish = (data) => {
    //full_name,phone,expertise_title,address
    // return data.email && data.expertise_title && data.full_name && data.phone && data.t_address && data.p_address;

    return data.email && data.category && typeof data.category === 'object' && data.category.length && data.full_name && data.phone && data.t_address && data.p_address;
};

export const isResumeComplete = (resume) => {
    const { personal, resume_info, category } = resume;
};


export const isBasicInfoComplete = (data, decs = 'follow company.', okText = 'Update Info', cancelText = 'Follow Later') => {
    if (isReadyForPublish(data)) {
        return true;
    } else {
        confirm({
            title: 'OOPS!! Basic info Incomplete.',
            content: 'Basic personal information (Email, Expertise Field, Full Name, Phone, Address) are required in order for you to ' + decs,
            okText,
            cancelText,
            onOk() {
                redirect('/user/update-resume');
            }
        });
        return false;
    }
};

export const isCompanyBasicInfoComplete = (companyInfo, decs = 'Bookmark candidate', okText = 'Update Info', cancelText = 'Add to Favourite Later') => {
    if (!companyInfo.p_address || !companyInfo.category_id || !companyInfo.phone) {
        confirm({
            title: 'OOS!! Basic info Incomplete.',
            content: 'Basic company information (Address, Category(Industry type), Contact etc) are required in order for you to ' + decs,
            okText,
            cancelText,
            onOk() {
                redirect('/company/update-profile');
            }
        });
        return false;
    }
    return true;
};


export const slugify = (string) => {
    if (!string) return '';
    const a = 'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
    const b = 'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
};


export const iconByExtension = (ext) => {

    switch (ext.toLowerCase()) {
        case 'pdf':
            return <Icon style={{ fontSize: 50 }} theme="filled" type="file-pdf" />
        case 'docm':
        case 'docx':
        case 'dotx':
        case 'dot':
            return <Icon style={{ fontSize: 50 }} theme="filled" type="file-word" />
        case 'jpg':
        case 'jpeg':
            return <Icon style={{ fontSize: 50 }} theme="filled" type="file-jpg" />
        default:
            return <Icon style={{ fontSize: 50 }} theme="filled" type="file" />
    }
}


export const stringLimit = (string, limit) => {
    if (!string) return '';

    if (string.length <= limit) return string;

    return string.substr(0, limit) + '..';
};


/**
 * Get Url Parameters
 * @param name
 * @returns {string}
 */
export const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};


export const concatCategories = (categories, asSlug = false) => {
    const categoryCopy = [...categories];
    if (!categoryCopy || !categoryCopy.length) return '';

    if (categoryCopy.length === 1) return asSlug ? slugify(categoryCopy[0].name) : categoryCopy[0].name;

    let lastEle = {};
    if (asSlug === false)
        lastEle = categoryCopy.pop();

    const names = categoryCopy.map(function (item) {
        return item.name;
    });
    if (asSlug === true) {
        return slugify(names.join(' ').toLowerCase());
    }

    return names.join(', ') + ' & ' + lastEle.name;
};


export const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null)
        return '';

    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

export const feedType = (item) => {
    const list = {
        SUGGESTION: 'We would love to hear how we make you happier!',
        COMPLIMENT: 'What do you want to share with us?',
        BUG: 'Describe what is not working.',
        ASK: 'What do you want to share with us?',
        COMPLAINT: 'What do you want to share with us?'
    };
    if (!item) return list;
    return list[item.toUpperCase()] || list['COMPLAINT'];
};


export const salaryParser = (salary, salary_to) => {
    if (!salary_to && !salary) return 'Negotiable';
    const intl = new Intl.NumberFormat('en-IN');
    if (salary && !salary_to) return intl.format(salary);
    else if (salary === salary_to) return intl.format(salary);
    else if (salary < salary_to) return intl.format(salary) + ' to ' + intl.format(salary_to);
    return 'Negotiable';

};

export const validEmail = (email) => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
};

export const isJobReadyForPublish = (formData) => {
    let output = false;
    try {
        ['availability', 'education', 'experience_year', 'job_location', 'openings', 'post_label', 'preferred_gender', 'descriptions', 'requirements', 'title', 'vacancy_deadline'].map((item) => {
            if (item === 'requirements' || item === 'descriptions') {
                output = formData[item].length > 0 && formData[item][0].length > 0;
            } else {
                if (typeof formData[item] === 'number') {
                    output = ![false, '', null, undefined, NaN].includes(formData[item]) && formData[item].toString().length > 0;
                }
                else
                    output = ![false, '', null, undefined, NaN].includes(formData[item]) && formData[item].length > 0
            }
            if (output === false) {
                throw new Error('');
            }
        });
    } catch (e) {
        console.log(e);
        return false;
    }
    return true;
}



/**
 randomString(16, 'aA');
 randomString(32, '#aA');
 randomString(64, '#A!');
 */
export const randomString = (strLength, chars) => {
    let mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '@#$%&*()';
    let result = '';
    for (let i = strLength;i > 0;--i) result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result;
};