import * as ActionTypes from './../ActionTypes';
import { sendDelete, sendGet, sendPost, sendPut } from "../../service/request";
import { openNotificationWithIcon } from "../../utils/notification";
import { isAuthenticated } from "../../utils/auth";
import { isBasicInfoComplete, isCompanyBasicInfoComplete, isReadyForPublish } from "../../utils/helpers";
import { userGenerateState } from "./UserActions";
import { jobGeneralState } from "./JobAction";


export const favouriteGeneralStateChange = ({ props, value, deep = '' }) => {
    return {
        type: ActionTypes.FAVOURITE_GENERAL_STATE,
        payload: { props, value, deep }
    }
};


/**
 *
 * @param reminder_notes
 * @param group_list_id
 * @returns {Function}
 */
export const addToFavourite = (reminder_notes = null, group_list_id = null) => {
    return (dispatch, getState) => {
        if (isAuthenticated({})) {
            const { company, favourite, user } = getState();

            if (favourite.favourite_type === 'USER_MARKED_BY_COMPANY' && !isCompanyBasicInfoComplete(company.item.company)) {
                return;
            } else if (favourite.favourite_type === 'JOB_MARKED_BY_USER' && !isBasicInfoComplete(user.data, 'add create favourite list', 'Update Info', 'Add to Favourite later')) {
                return;
            }

            return sendPost('/bmark', {
                user_id: favourite.favourite_employee_id,
                job_id: favourite.favourite_job_id,
                reminder_notes,
                group_list_id
            }, {}, true).then(({ data }) => {
                if (favourite.favourite_type === 'USER_MARKED_BY_COMPANY')
                    dispatch(userGenerateState({ props: 'profilePage', value: true, deep: '_bk_mk' }));

                if (favourite.favourite_type === 'JOB_MARKED_BY_USER')
                    dispatch(jobGeneralState({ props: 'jobDetailPage', value: true, deep: 'is_bk_m' }));

                openNotificationWithIcon('success', {
                    title: 'Yeey!! Added to favourite list',
                    description: "User has been added to your favourite list (Dashboard > My Favourites)."
                }, 7);
            }).catch(err => {
            }).finally(() => {
                dispatch(favouriteGeneralStateChange({ props: 'favouriteModal', value: false }));
                dispatch(favouriteGeneralStateChange({ props: 'favourite_employee_id', value: null }));
                dispatch(favouriteGeneralStateChange({ props: 'favourite_job_id', value: null }));
            });
        }
    }
};


/**
 * Favourite Modal Open
 * @param {*} title 
 * @param {*} id 
 */
export const favouriteModalOpen = ({ favourite_type, job_id }) => {
    return (dispatch, getState) => {
        if (isAuthenticated({})) {
            const { data } = getState().user;
            if (isReadyForPublish(data)) {
                dispatch(favouriteGeneralStateChange({
                    props: 'favourite_type',
                    value: favourite_type
                }));
                dispatch(favouriteGeneralStateChange({
                    props: 'favourite_job_id',
                    value: job_id
                }));
                dispatch(favouriteGeneralStateChange({
                    props: 'favouriteModal',
                    value: true
                }));
            } else {
                confirm({
                    title: 'Basic info Incomplete.',
                    content: 'Basic personal information (Email, Expertise Field, Full Name, Phone, Address) are required in order for you to apply jobs.',
                    okText: 'Update Info',
                    cancelText: 'Apply Later',
                    onOk() {
                        redirect('/user/update-resume');
                    }
                });
            }
        } else {
            dispatch({ type: ActionTypes.AUTH_MODEL_SWITCH, payload: true });
        }
    }
};



/**
 *
 * @param id
 * @param group_list_id
 * @returns {Function}
 */
export const updateFavourite = (id, group_list_id = null) => {
    return (dispatch, getState) => {
        if (isAuthenticated({})) {
            return sendPut(`/bmark/${id}`, {
                group_list_id
            }, {}, true).then(({ data }) => {
                dispatch(fetchMyFavouriteList({}, 'USER_MARKED_BY_COMPANY'));
                openNotificationWithIcon('success', {
                    title: 'Move Favourite User',
                    description: "User was moved to new group"
                }, 5);
            }).catch(err => {
            }).finally(() => {
            });
        }
    }
};
/**
 * Fetch favourite list (company,user)
 * @param ctx
 * @param favouriteType
 * @param pagination
 * @return {function(*, *): Promise<T | never>}
 */
export const fetchMyFavouriteList = (ctx, favouriteType = 'JOB_MARKED_BY_USER', pagination = {}) => {
    return (dispatch, getState) => {

        return sendGet('/bmark/favourites', {
            favourite_type: favouriteType,
            groupId: getState().group.selectedGroup,
            ...pagination
        }, true, ctx).then(({ data }) => {
            if (favouriteType === 'USER_MARKED_BY_COMPANY') {
                dispatch(favouriteGeneralStateChange({ props: 'favourite_users', value: data.favourites }));
                dispatch(favouriteGeneralStateChange({ props: 'pagination', value: data.pagination }));
            } else if (favouriteType === 'JOB_MARKED_BY_USER') {
                dispatch(favouriteGeneralStateChange({ props: 'favourite_jobs', value: data.favourites }));
                dispatch(favouriteGeneralStateChange({ props: 'pagination', value: data.pagination }));
            }
        }).catch(err => {
        });
    }
};


/**
 * Remove favourite list
 * @return {function(*, *): Promise<T | never>}
 * @param favouriteId
 * @param favType
 */
export const removeFavourite = (favouriteId, favType = 'user') => {
    return (dispatch, getState) => {
        const { favourite_users, favourite_jobs } = getState().favourite;
        let favourites = favourite_users;
        if (favType === 'job') {
            favourites = favourite_jobs;
        }
        return sendDelete(`/bmark/${favouriteId}`, {}, true).then(({ data }) => {
            const newFavouriteList = favourites.filter(function (item) {
                return (item.favourite_id !== favouriteId)
            });
            if (favType === 'user')
                dispatch(favouriteGeneralStateChange({ props: 'favourite_users', value: newFavouriteList }));
            else
                dispatch(favouriteGeneralStateChange({ props: 'favourite_jobs', value: newFavouriteList }));

            openNotificationWithIcon('success', {
                title: 'Removed from favourite list',
                description: "Successfully removed from your favourite list."
            }, 7);

        }).catch(err => {
            console.log(err);
        });
    }
};