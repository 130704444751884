import * as ActionTypes from './../ActionTypes';

const INITIAL_STATE = {
    favouriteModal: false,
    favourite_employee_id: null,
    favourite_job_id: null,
    favourite_jobs: [],
    favourite_users: [],
    pagination: {},
    favourite_type: '' //COMPANY_MARKED_BY_USER, USER_MARKED_BY_COMPANY, JOB_MARKED_BY_USER
};

const AppReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.FAVOURITE_GENERAL_STATE:
            if (!action.payload.deep) {
                state[action.payload.props] = action.payload.value;
            }
            else
                state[action.payload.props][action.payload.deep] = action.payload.value;
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default AppReducer;