import * as ActionTypes from './../ActionTypes';

const INITIAL_STATE = {
    signUp: {
        email: '',
        password: '',
        password_confirmation: '',
        is_company_account: null,
        full_name: '',
        agreement: false,
        loader: false,
        errors: [],
        accountCreated: false,
        incorrectEmail: {
            correctEmail: '',
            correctModal: false,
            loader: false,
            emailToken: '',
        }
    },
    signIn: {
        email: '',
        password: '',
        loader: false,
        errors: [],
        unverifiedAttempt: false
    },
    retryInterval: null,
    verificationLinkSent: false,
    verifyLoader: false,
    reset: {
        email: '',
        loader: false,
        errors: [],
        password: '',
        password_confirmation: '',
        verificationSent: false
    },
    updatePassword: {
        loader: false,
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
    },
    authModel: false,
    authSwitch: true,
    authCheck: false,
    verification: {
        email: null,
        phone: null,
        token: '',
        ica: ''
    },
    afterLoginSuccess: null
};

const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.AUTH_INPUT_CHANGE:
            state[action.payload.type][action.payload.props] = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.AUTH_LOADER:
            state[action.payload.type].loader = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.AUTH_V_ERRORS:
            state[action.payload.type].errors = action.payload.errors;
            if (typeof action.payload.errors === 'object' && action.payload.errors.length > 0) {
                action.payload.errors.map(function (item) {
                    if (item.context && item.context.emailNotVerified === true) {
                        state[action.payload.type].unverifiedAttempt = true;
                    }
                });
            }
            return {
                ...state
            };
        case ActionTypes.AUTH_SUCCESSFUL:
            if (action.payload.type === 'signUp') {
                state.signUp.accountCreated = true;
                state.signUp.incorrectEmail.emailToken = action.payload.encETk
            }
            // state[action.payload.type].loader = false;
            state[action.payload.type].password = '';
            state[action.payload.type].loader = false;
            state[action.payload.type].password_confirmation = '';
            state[action.payload.type].errors = [];
            state.authModel = false;
            return {
                ...state
            };
        case ActionTypes.AUTH_MODEL_SWITCH:
            state.authModel = action.payload;
            return {
                ...state
            };
        case ActionTypes.AUTH_SCREEN_SWITCH:
            state.authSwitch = !state.authSwitch;
            return {
                ...state
            };
        case ActionTypes.AUTH_RESET_LINK_SUCCESS:
            state.reset.verificationSent = action.payload;
            return {
                ...state
            };
        case ActionTypes.AUTH_VERIFICATION_STATE:
            state.verification[action.payload.prop] = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.AUTH_STATE_RESET:
            state.signUp.accountCreated = false;
            state.verificationLinkSent = false;
            state.signUp.loader = false;
            state.signUp.is_company_account = null;
            state.signIn.loader = false;
            state.signUp.agreement = false;
            state.signIn.unverifiedAttempt = false;
            return {
                ...state
            };
        case ActionTypes.AUTH_INCORRECT_STATE_CHANGE:

            state.signUp.incorrectEmail[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.AUTH_GENERAL_STATE:
            if (action.payload.type) {
                state[action.payload.props][action.payload.type] = action.payload.value;
            } else {
                state[action.payload.props] = action.payload.value;
            }
            return {
                ...state
            };
        default: {
            return {
                ...state
            }
        }
    }
};
export default AuthReducer;
