import * as ActionTypes from "./../ActionTypes";
import {sendGet, sendPost, sendPut} from "../../../src/service/request";
import {openNotificationWithIcon} from "../../utils/notification";
import {redirect} from "../../utils/redirect";
import {setCookie} from "../../utils/session";
import {authenticatedUser, settingInputChange} from "./UserActions";
import {Modal, Button} from 'antd';

const {confirm} = Modal;

// export const authInputChange = ({props, value, type = "signIn"}) => {
//     return {
//         type: ActionTypes.AUTH_INPUT_CHANGE,
//         payload: {props, value, type}
//     };
// };
//
//
// export const incorrectEmailStateChange = ({props, value}) => {
//     return {
//         type: ActionTypes.AUTH_INCORRECT_STATE_CHANGE,
//         payload: {props, value}
//     };
// };
//
//
// export const authModelSwitch = (payload) => {
//     return {
//         type: ActionTypes.AUTH_MODEL_SWITCH,
//         payload
//     };
// };




export const checkUnsubscribeStatusAction = (unsubscribeToken) => {
    return (dispatch) => {

        sendGet("/email/status", {unsubscribeToken})
            .then(response => {
                if (response.data && response.data.data.subscribe_status === 0) {
                    dispatch({
                        type: ActionTypes.EMAIL_GENERAL_STATE,
                        payload: {props: 'hasUnsubscribed', value: true}
                    });
                }
            })
            .catch(err => {
            }).finally(() => {
        });
    };
};


export const unsubscribeAction = (unsubscribeToken) => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            sendPost("/email/unsubscribe", {unsubscribeToken})
                .then(response => {
                    if (response.data.data.subscribe_status === 1) {
                        dispatch({
                            type: ActionTypes.EMAIL_GENERAL_STATE,
                            payload: {props: 'hasUnsubscribed', value: false}
                        });
                    } else {
                        dispatch({
                            type: ActionTypes.EMAIL_GENERAL_STATE,
                            payload: {props: 'hasUnsubscribed', value: true}
                        });
                    }

                    openNotificationWithIcon('success', {
                        title: response.data.msg
                    });
                    resolve(response);
                })
                .catch(err => {
                    reject(resolve);
                })
        });

    };
};
