import * as ActionTypes from './../ActionTypes';

const INITIAL_STATE = {
    data: []
};

const CategoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.CATEGORY_ALL_STATE:
            state.data = action.payload;
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default CategoryReducer;