import * as ActionTypes from './../ActionTypes';
import { sendDelete, sendGet, sendPost, sendPut } from "../../service/request";
import { openNotificationWithIcon } from "../../utils/notification";
import Router from 'next/router'
import { isAuthenticated } from "../../utils/auth";
import { isBasicInfoComplete, isReadyForPublish, isCompanyBasicInfoComplete, isJobReadyForPublish } from "../../utils/helpers";
import { Modal } from 'antd';
import { redirect } from "../../utils/redirect";
import { getCookie, removeCookie } from '../../service/session';

const { confirm } = Modal;

let typingTimer;
var doneTypingInterval = 1600;

export const jobInputChange = ({ props, value, type = 'personal', index = null }, saveCall = true) => {
    return (dispatch, getState) => {
        const { job } = getState();
        dispatch({
            type: ActionTypes.JOB_INPUT_CHANGE,
            payload: { props, value, type, index }
        });


        if (saveCall && !job.item.is_update) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(function () {
                let input_type = props;
                if (!isNaN(props)) {
                    input_type = type;
                }
                dispatch(submitJobPost(true, input_type));
            }, doneTypingInterval);
        }

        if ((type === 'descriptions' || type === 'requirements'))
            dispatch(jobPostRequirementIncrement(type));
    }
};


// export const jobSectionIncrement = (value) => {
//     return {type: ActionTypes.JOB_SECTION_INCREMENT, payload: {value}};
// };

export const submitJobPost = (isTemp = true, props = '') => {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.JOB_GENERAL_STATE,
            payload: {
                props: 'saving',
                value: true
            }
        });
        const { item } = getState().job;
        let { profileInComplete } = getState().company;
        const uniqueUserToken = getCookie('_u_un_d');

        if (getState().job.addGuestJob === true) {
            profileInComplete = false;
            item._uuid = uniqueUserToken;
        }

        if (!profileInComplete) {
            dispatch({
                type: ActionTypes.JOB_GENERAL_STATE,
                payload: {
                    props: 'previewJob',
                    value: false
                }
            });
            return sendPut('/job', item, {}, true).then(({ data }) => {
                if (data.temp_post_id) {
                    dispatch(jobInputChange({ props: 'id', value: data.temp_post_id }, false));
                    if (data.job_code) {
                        dispatch(jobInputChange({ props: 'job_code', value: data.job_code }, false));
                        const { socketInstance } = getState().app;
                        if (socketInstance) {
                            socketInstance.emit('saved_temp_job', {
                                guest_id: uniqueUserToken,
                                job_code: data.job_code,
                                saved_item: props,
                            });
                        }


                    }
                    const href = location.pathname + '?temp_post_id=' + data.temp_post_id;
                    Router.push(href, href, { shallow: true });
                }
                dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'previewJob', value: true } });
            }).finally(() => {
                dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'saving', value: false } });
            })
        } else {
            openNotificationWithIcon('error', {
                title: 'Company Profile Incomplete',
                description: 'Please complete your company profile before uploading a job post.'
            });
        }
    }
};


export const updateJobPost = () => {
    return (dispatch, getState) => {
        dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'saving', value: true } });
        const { item } = getState().job;
        const { profileInComplete } = getState().company;

        if (!profileInComplete) {
            return sendPut('/job/' + item.id, item, {}, true).then(({ data }) => {
                openNotificationWithIcon('success', {
                    title: 'Job updated',
                    description: "Changes you've made has been saved successfully."
                });

                dispatch({ type: ActionTypes.JOB_STATE_CLEAR });
                Router.push('/company/job-posts', '/company/job-posts', { shallow: true });
            }).finally(() => {
                dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'saving', value: false } });
            })
        } else {
            openNotificationWithIcon('error', {
                title: 'Company Profile Incomplete',
                description: 'Please complete your company profile before uploading a job post.'
            });
        }
    }
};


/**
 * 
 * @param {*} jobData 
 */
export const publishJobPost = (jobData = null) => {
    return (dispatch, getState) => {

        let { item } = getState().job;
        if (jobData) {
            item = jobData;
        }
        if (!isAuthenticated({})) {
            dispatch({ type: ActionTypes.AUTH_MODEL_SWITCH, payload: true });
            dispatch({
                type: ActionTypes.AUTH_GENERAL_STATE, payload: {
                    props: 'afterLoginSuccess', value: () => {
                        redirect('/company');
                    }
                }
            });
            return;
        }

        if (!isCompanyBasicInfoComplete(getState().company.item.company, 'Publish your job', 'Update Info', 'Publish Later')) {
            return;
        }
        if (!isJobReadyForPublish(item)) {
            confirm({
                title: 'Not ready for publish',
                content: 'Some required information for the job is still incomplete. Please complete them first and trying publish again',
                okText: 'Update Info',
                cancelText: 'Publish Later',
                onOk() {
                    redirect(`/company/post-job?temp_post_id=${item.id}`);
                }
            });
            return;
        }

        dispatch({
            type: ActionTypes.JOB_GENERAL_STATE,
            payload: { props: 'publishing', value: true }
        });

        sendPost('/job', item, {}, true).then(({ data }) => {
            openNotificationWithIcon('success', {
                title: 'Yeey!! Job published',
                description: "Your job is live now. People will now be able to view, submit resume to your job post."
            });

            dispatch({ type: ActionTypes.JOB_STATE_CLEAR });
            if (jobData) {
                //jobData argument is passed only when publishing temporary job
                dispatch(fetchMyTemporaryJobs());
                dispatch(myJobPost());
            }

            const href = '/company/job-posts';
            Router.push(href, href, { shallow: true });

        }).finally(() => {
            dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'publishing', value: false } });
        });
    }
};



/**
 * 
 * @param {*} jobData 
 */
export const unpublishJobPost = (id, status) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendPut(`/job/unpublish/${id}`, { status }, {}, true).then((res) => {
                openNotificationWithIcon('success', {
                    title: status ? 'Job Unpublished' : 'Job Published',
                    description: status ? "Your job is now removed from live search. Employers won't be able to see / apply etc to this job." : "Your job has been published and will reappear on the live website."
                });
                dispatch(myJobPost());

                resolve(res);
            }).catch((err) => {
                reject(err);
            }).finally(() => {
            });
        });
    }
}





/**
 * Fetch temporary post to show it in the form
 * @param ctx
 * @param tempPostId
 * @param withCompany
 * @param withCategory
 * @returns {function(*, *): Promise<T | never>}
 */
export const fetchTempPost = (ctx, tempPostId, withCompany = false, withCategory = false, guest_id = null, job_code = null) => {
    return (dispatch) => {
        //if withCompany is true job post is fetched along with the related company info.
        return new Promise((resolve, reject) => {
            return sendGet('/job/temp/post', {
                temp_post_id: tempPostId,
                with_company: withCompany,
                with_category: withCategory,
                guest_id: guest_id,
                job_code
            }, true, ctx).then(res => {
                dispatch({ type: ActionTypes.JOB_STATE, payload: { value: res.data.post } });
                dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'previewJob', value: true } });
                resolve(res);
            }).catch(err => {
                dispatch(clearJobState());
                reject(err);
            })
        })

    }
};


export const fetchJobDetailsBySlug = (ctx, slug, isDetailsPage = false) => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            return sendGet(`/job/${slug}`, {}, true, ctx).then(({ data }) => {
                if (isDetailsPage)
                    dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { value: data.post, props: 'jobDetailPage' } });
                else
                    dispatch({ type: ActionTypes.JOB_STATE, payload: { value: data.post, onlyDetail: true } });
                resolve(data);
            }).catch(err => {
                reject(err);
            })
        });
    }
};

export const fetchJobDetailsById = (ctx, id, isUpdate = false) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendGet(`/job/${id}/id?is_update=${isUpdate}`, {}, true, ctx).then(({ data }) => {
                dispatch({ type: ActionTypes.JOB_STATE, payload: { value: data.post, onlyDetail: false, isUpdate } });
                resolve(data);
            }).catch(err => {
                reject(err);
            })
        });
    }
};

export const clearJobState = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.JOB_STATE_CLEAR });
    }
};


export const jobPostRequirementIncrement = (type = 'requirements') => {
    return {
        type: ActionTypes.JOB_REQUIREMENT_INCREMENT,
        payload: { type }
    };
};


export const jobFilterInputChange = ({ props, value, searchNow = true, limit, hits }) => {
    return (dispatch, getState) => {
        if (!limit) limit = 100;

        dispatch({
            type: ActionTypes.JOB_FILTER,
            payload: { props, value, hits }
        });

        if (searchNow === true)
            dispatch(getAllJobs({ ...getState().job.filter, limit }));
    };
};


export const getAllJobs = (filter = {}, ctx, isForHomePage = false) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'fetchingJobList', value: true } });
        return new Promise((resolve, reject) => {
            return sendGet('/job', filter, true, ctx).then(({ data }) => {
                if (isForHomePage) {
                    dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'homePageJobList', value: data.data } })
                } else {
                    if (data.category)
                        dispatch({
                            type: ActionTypes.JOB_GENERAL_STATE,
                            payload: { props: 'selectedHits', value: [data.category] }
                        });
                    dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'data', value: data.data } })
                }
                resolve(data);
            }).catch(err => {
                reject(err);
            }).finally(() => {
                dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'fetchingJobList', value: false } });
            })
        });

    }
};


export const applyModalOpen = (title, id) => {
    return (dispatch, getState) => {
        if (isAuthenticated({})) {
            const { data } = getState().user;
            if (isReadyForPublish(data)) {
                dispatch({ type: ActionTypes.JOB_APPLY_STATE_CHANGE, payload: { props: 'modal', value: true } });
                dispatch({ type: ActionTypes.JOB_APPLY_STATE_CHANGE, payload: { props: 'selectedTitle', value: title } });
                dispatch({ type: ActionTypes.JOB_APPLY_STATE_CHANGE, payload: { props: 'selectedId', value: id } });
            } else {
                confirm({
                    title: 'Basic info Incomplete.',
                    content: 'Basic personal information (Email, Expertise Field, Full Name, Phone, Address) are required in order for you to apply jobs.',
                    okText: 'Update Info',
                    cancelText: 'Apply Later',
                    onOk() {
                        redirect('/user/update-resume');
                    }
                });
            }
        } else {
            dispatch({ type: ActionTypes.AUTH_MODEL_SWITCH, payload: true });
        }
    }
};


export const applyModalClose = () => {
    return { type: ActionTypes.JOB_APPLY_STATE_CHANGE, payload: { props: 'modal', value: false } };
};


export const selectRenewJob = (id) => {
    return {
        type: ActionTypes.JOB_RENEW_SELECTION,
        payload: { id }
    }
};


export const jobRenewChange = ({ props, value }) => {
    return {
        type: ActionTypes.JOB_RENEW_CHANGE,
        payload: { props, value }
    }
};

export const renewJob = (id, newDate, type) => {
    return (dispatch) => {
        return sendPut('/job/renew/' + id, { date: newDate, type }, {}, true).then((res) => {
            openNotificationWithIcon('success', {
                title: 'Yeey!! Job renewed',
                description: "Job was renewed and is live now"
            });
            dispatch(myJobPost());
            dispatch(jobRenewChange({ props: 'date', value: '' }));
            dispatch(jobRenewChange({ props: 'modal', value: false }));
            dispatch(jobRenewChange({ props: 'renewalType', value: '' }));
            dispatch(jobRenewChange({ props: 'item', value: {} }));
        })
    }
};


/**
 *
 * @param declarations
 * @returns {Function}
 */
export const applyJob = (declarations = null) => {
    return (dispatch, getState) => {
        if (isAuthenticated({})) {
            const { job, user } = getState();
            if (isBasicInfoComplete(user.data)) {
                const { apply } = job;
                return sendPost('/job/application/submit', {
                    job_id: apply.selectedId,
                    declarations
                }, {}, true).then(({ data }) => {
                    dispatch({
                        type: ActionTypes.JOB_APPLY_STATE_CHANGE,
                        payload: { props: 'declaration', value: '', applied: true, jobId: apply.selectedId }
                    });
                    openNotificationWithIcon('success', {
                        title: 'Yeey!! Application submitted',
                        description: "Your resume has been sent to job publisher. You might soon be contacted by the publisher. Thank you for using Rojgari Sanjal."
                    }, 7);
                }).catch(err => {
                    //...
                }).finally(() => {
                    dispatch({ type: ActionTypes.JOB_APPLY_STATE_CHANGE, payload: { props: 'modal', value: false } });
                });
            } else {
            }
        }
    }
};


export const myJobPost = (ctx) => {
    return (dispatch) => {
        return sendGet('/job/my-post/all', {}, true, ctx).then(({ data }) => {
            dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'myPosts', value: data.data } })
        }).catch(err => {
        });
    }
};

export const jobGeneralState = ({ props, value, deep }) => {
    return {
        type: ActionTypes.JOB_GENERAL_STATE,
        payload: { props, value, deep }
    }
};


/**
 * job applicants
 * @param slug
 * @param expiry
 * @param ctx
 * @returns {function(*, *): Promise<T | never>}
 */
export const applicantsList = (slug, expiry, ctx, { page = 1, limit = 6 }) => {
    return (dispatch) => {
        return sendGet('/user/applicants', { job: slug, expiry, page, limit }, true, ctx).then(({ data }) => {
            let shortListedCount = 0;
            data.data.map(function (item) {
                if (item.save_type === 1) {
                    shortListedCount++;
                }
            });

            dispatch({
                type: ActionTypes.JOB_GENERAL_STATE,
                payload: { props: 'applicantStatsShortlisted', value: shortListedCount }
            });

            dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'jobStateApplicant', value: data.job } });
            dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'applicants', value: data.data } });
            dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'applicant_pagination', value: data.pagination } });
        }).catch(err => {
            // redirect('/company/job-posts', ctx)
        })
    }
};


/**
 *
 * @param id
 * @returns {function(*): Promise<T | never>}
 */
export const deletePost = (id, isTemp = false) => {
    return (dispatch) => {
        return sendDelete('/job/' + id, { isTemp }, {}, true).then(({ data }) => {
            openNotificationWithIcon('success', {
                title: 'Job deleted',
                description: "Your job post was deleted."
            });
            if (isTemp) {
                dispatch(fetchMyTemporaryJobs());
            } else {
                dispatch(myJobPost());
            }
        })
    }
};


export const myAppliedJob = (ctx) => {
    return (dispatch) => {
        return sendGet('/job/applied-job', {}, true, ctx).then(({ data }) => {
            dispatch({ type: ActionTypes.JOB_GENERAL_STATE, payload: { props: 'myAppliedJobs', value: data.data } })
        }).catch(err => {
        });
    }
};


export const fetchRelatedJobs = ({ ctx, job_id, category_id }) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendGet(`/job/related/${category_id}/${job_id}`, {}, true, ctx).then((data) => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }
}

export const assignTemporaryJobToCompany = ({ guest_id }) => {
    return () => {
        return new Promise((resolve, reject) => {
            return sendPost('/job/assign-temp', { guest_id }).then((res) => {
                removeCookie('_u_un_d');
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    }
}

/**
 * Fetch companies temporary posts
 * @param {*} param0 
 */
export const fetchMyTemporaryJobs = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendGet('/job/temp/posts').then((res) => {
                dispatch(jobGeneralState({ props: 'temporaryPost', value: res.data.data }));

                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    }
}