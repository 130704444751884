import * as ActionTypes from './../ActionTypes';
import { sendGet, sendPost } from "../../service/request";
import { openNotificationWithIcon } from "../../utils/notification";


export const generateStateUpdate = (actionType, { props, value }) => {
    return {
        type: actionType,
        payload: { props, value }
    }
};

export const generalStateChange = ({ props, value, deep = '' }) => {
    return {
        type: ActionTypes.APP_GENERAL_STATE,
        payload: { props, value, deep }
    }
};


export const contactAction = () => {
    return (dispatch, getState) => {
        const { contact } = getState().app;
        dispatch(generalStateChange({ props: 'savingContact', value: true }));
        sendPost('/contact', contact).then(({ data }) => {
            openNotificationWithIcon('success', {
                title: 'Sent',
                description: 'Thank you!! See you soon.'
            });
            dispatch(generalStateChange({ props: 'contactSuccess', value: true }));
            dispatch(generalStateChange({ props: 'contact', value: '', deep: 'full_name' }));
            dispatch(generalStateChange({ props: 'contact', value: '', deep: 'email' }));
            dispatch(generalStateChange({ props: 'contact', value: '', deep: 'phone' }));
            dispatch(generalStateChange({ props: 'contact', value: '', deep: 'message' }));
        }).catch(err => {
        }).finally(() => {
            dispatch(generalStateChange({ props: 'savingContact', value: false }));
        });
    };
};

export const fetchFaqActions = (ctx) => {
    return (dispatch) => {
        return sendGet('/faq', {}, false, ctx).then(({ data }) => {
            dispatch(generalStateChange({ props: 'faqs', value: data.data })); 
            dispatch(generalStateChange({ props: 'faqs_permanent', value: data.data }));

        }).catch(err => {
        }).finally(() => {
        });
    };
};

export const filterFaqs = (search) => {
    return (dispatch,getState) =>{
        search = search.toUpperCase();
        const results = [];
        let entry;
        const source = getState().app.faqs_permanent;

        for (let index = 0;index < source.length; ++index) {
            entry = source[index];
            if (entry && entry.question && entry.question.toUpperCase().indexOf(search) !== -1) {
                results.push(entry);
            }
        }
        
        dispatch(generalStateChange({ props: 'faqs', value: results }));
    }
}

export const subscribeAction = ({ email }) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendPost('/subscribe', { email }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        })
    }
}


export const applyApplication = ({ email, mobile, full_name, id }) => {
    return () => {
        return new Promise((resolve, reject) => {
            return sendPost(`/blog/apply/${id}`, { email, mobile, full_name }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        })
    }
}

