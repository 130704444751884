import {notification} from 'antd';

/**
 *
 * @param type (success,error)
 * @param title
 * @param description
 * @param duration
 */
export const openNotificationWithIcon = (type, {title, description}, duration = 2.5) => {
    notification[type]({
        duration: duration,
        message: title || 'Notification Title',
        description: description || (type === 'success' ? 'Congrats!! action was successful.' : 'Something went terribly wrong'),
    });
};
