import cookie from "js-cookie";


export const setCookie = (key, value) => {
    if (process.browser) {
        cookie.set(key, value, {
            expires: 7,
            path: "/"
        });
    }
};

export const removeCookie = key => {
    if (process.browser) {
        cookie.remove(key, {
            expires: 1
        });
    }
};

export const getCookie = (key, req, hasEqualSign = false) => {
    return process.browser
        ? getCookieFromBrowser(key)
        : getCookieFromServer(key, req, hasEqualSign);
};

const getCookieFromBrowser = key => {
    return cookie.get(key);
};

const getCookieFromServer = (key, req, hasEqualSign = false) => {
    if (!req) return undefined;
    if (!req.headers.cookie) {
        return undefined;
    }
    const rawCookie = req.headers.cookie
        .split(";")
        .find(c => c.trim().startsWith(`${key}=`));
    if (!rawCookie) {
        return undefined;
    }
    if (hasEqualSign) {
        return rawCookie.replace(`${key}=`, '');
    }
    return rawCookie.split("=")[1];
};