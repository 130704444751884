import * as ActionTypes from './../ActionTypes';
import { sendDelete, sendGet, sendPost, sendPut } from "../../service/request";
import { openNotificationWithIcon } from "../../utils/notification";
import { ucFirst } from "../../utils/helpers";

export const resumeInputChange = ({ props, value, type = 'personal', index = null }) => {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.RESUME_INPUT_CHANGE,
            payload: { props, value, type, index }
        });

        if (type === 'language') {
            let language = getState().resume.updateCopy.resume_info.languages;
            if (language[index].item.length && language[index].value) {
                dispatch({
                    type: ActionTypes.RESUME_SECTION_INCREMENT,
                    payload: { value: 'language' }
                });
            }
        }
    };
};


export const resumeSectionIncrement = (value) => {
    return { type: ActionTypes.RESUME_SECTION_INCREMENT, payload: { value } };
};


export const submitOtherInfoResume = () => {
    return (dispatch, getState) => {
        const { updateCopy } = getState().resume;
        dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'otherInfoLoading', value: true } });
        sendPut('/resume/info', updateCopy.resume_info, {}, true).then(({ data }) => {
            if (data.status === true) {
                openNotificationWithIcon('success', {
                    title: 'Update Resume',
                    description: 'Resume information were updated successfully'
                });
                dispatch({ type: ActionTypes.RESUME_UPDATE_SUCCESS, payload: true, value: 'resume_info' });
                dispatch({ type: ActionTypes.RESUME_SUCCESS_STATE, payload: { props: 'resume_info', value: data.data } });
            }
        }).catch(err => {
            if (err.response)
                dispatch({
                    type: ActionTypes.RESUME_GENERAL_STATE,
                    payload: { props: 'resumeValidationErrors', value: err.response.data.errors }
                });
        }).finally(() => {
            dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'otherInfoLoading', value: false } });
        });
    }
};


export const submitAcademicResume = () => {
    return (dispatch, getState) => {
        const { updateCopy } = getState().resume;
        dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'academicLoading', value: true } });

        return sendPut('/resume/academics', { academics: updateCopy.academics }, {}, true).then(({ data }) => {
            if (data.status === true) {
                openNotificationWithIcon('success', {
                    title: 'Update Resume',
                    description: 'Academic information were updated successfully'
                });
                dispatch({ type: ActionTypes.RESUME_SUCCESS_STATE, payload: { props: 'academic', value: data.data } });

            }
        }).catch(err => {
            switch (err.response.status) {
                case 400:
                    dispatch({
                        type: ActionTypes.RESUME_GENERAL_STATE,
                        payload: { props: 'academicUpdate', value: false }
                    });
                    dispatch({
                        type: ActionTypes.RESUME_GENERAL_STATE,
                        payload: { props: 'resumeAcademicValidationErrors', value: err.response.data.errors }
                    });
                    break;
            }
        }).finally(() => {
            dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'academicLoading', value: false } });
        })

    }
};


export const submitExperienceResume = () => {
    return (dispatch, getState) => {
        const { updateCopy } = getState().resume;
        dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'experienceLoading', value: true } });

        return sendPut('/resume/experiences', { experiences: updateCopy.experiences }, {}, true).then(({ data }) => {
            if (data.status === true) {
                openNotificationWithIcon('success', {
                    title: 'Update Resume',
                    description: 'Work information were updated successfully'
                });
                dispatch({ type: ActionTypes.RESUME_SUCCESS_STATE, payload: { props: 'experience', value: data.data } });
            }
        }).catch(err => {
            switch (err.response.status) {
                case 400:
                    dispatch({
                        type: ActionTypes.RESUME_GENERAL_STATE,
                        payload: { props: 'academicUpdate', value: false }
                    });
                    dispatch({
                        type: ActionTypes.RESUME_GENERAL_STATE,
                        payload: { props: 'resumeExperienceValidationErrors', value: err.response.data.errors }
                    });

                    break;
            }
        }).finally(() => {
            dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'experienceLoading', value: false } });
        })

    }
};


export const deleteResume = ({ id, props, action }) => {
    return (dispatch) => {
        let endpoint = '/resume';
        if (action === 'delete') {
            switch (props) {
                case 'experience':
                    endpoint += '/experiences';
                    break;
                case 'academic':
                    endpoint += '/academics';
                    break;
            }
            return sendDelete(endpoint, { id }, {}, true).then(({ data }) => {
                if (data.status === true) {
                    openNotificationWithIcon('success', {
                        title: 'Delete Resume',
                        description: ucFirst(props) + ' was deleted'
                    });
                    dispatch({ type: ActionTypes.RESUME_SUCCESS_STATE, payload: { props, value: data.data } });
                }
            }).catch(() => {
                openNotificationWithIcon('error', {
                    title: 'Delete Resume Failed',
                    description: ucFirst(props) + ' delete failed. Something went wrong'
                });
            });
        } else {
            dispatch({ type: ActionTypes.RESUME_GENERAL_STATE, payload: { props: 'academicUpdate', value: true } });
            dispatch({ type: ActionTypes.RESUME_CANCEL_SECTION, payload: { props, id } });
        }
    };
};


export const downloadResume = ({ userId, props, action }) => {
    return (dispatch) => {
        dispatch(downloadSampleResume(false));
        return new Promise(function (resolve, reject) {
            sendPost('/resume/download', { userId }, {}, true).then((res) => {
                dispatch({ type: ActionTypes.RESUME_DOWNLOAD_INFO, payload: { resume_info: res.data.data } });
                dispatch({
                    type: ActionTypes.RESUME_GENERAL_STATE,
                    payload: { props: 'downloadResumeModal', value: true }
                });
                resolve(res);
            }).catch((err) => {
                reject(err);

            });
        });
    }
};

export const downloadSampleResume = (value = true) => {
    return {
        type: ActionTypes.RESUME_DOWNLOAD_SAMPLE,
        payload: { value }
    };
};

