import * as ActionTypes from './../ActionTypes';

const defaultItem = {
    id: '',
    category_id: '',
    sub_category_id: '',
    title: '',
    availability: null,
    post_label: null,
    salary: '',
    salary_to: '',
    negotiable: false,
    experience_year: null,
    job_location: '',
    education: '',
    openings: '',
    vacancy_deadline: '',
    preferred_gender: null,
    descriptions: [''],
    requirements: [''],
    is_update: false
};

const INITIAL_STATE = {
    //used in the job update and save form
    item: defaultItem,
    //used in the details page.
    details: {},
    addGuestJob: false,
    data: [],
    temporaryPost: [],
    myPosts: [],
    homePageJobList: [],
    applicants: [],
    applicant_pagination: {},
    applicantStatsShortlisted: 0,
    jobStateApplicant: {},
    selectedCandidate: {},
    myAppliedJobs: [],
    applicantDecModal: false,
    selectedApplicant: {},
    fetchingJobList: false,
    filter: {
        category: null,
        sub_category: null,
        industry_category: null,
        experience: '',
        posted_on: '',
        search: '',
        address: '',
        availability: ''
    },
    updateCopy: {},
    saving: false,
    previewJob: false,
    companyUpdate: false,
    publishing: false,
    deleteConfirm: {
        confirm: false,
        type: '',
        id: ''
    },
    renewJob: {
        modal: false,
        id: '',
        date: '',
        item: {},
        renewalType: ''
    },
    activeTabKey: '1',
    jobDetailPage: {},
    selectedHits: [],
    apply: {
        modal: false,
        declaration: '',
        selectedTitle: '',
        selectedId: ''
    }
};

const JobReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.JOB_STATE:
            //do not update the item(used in the form) while loading the detail page.
            if (!action.payload.onlyDetail) {
                state.item = action.payload.value;
                if (!state.item.descriptions.length) {
                    state.item.descriptions.push('')
                }
                if (!state.item.requirements.length) {
                    state.item.requirements.push('')
                }
            }

            if (action.payload.isUpdate) {
                state.item.is_update = true;
            }

            if (action.payload.value.category) {
                state.item.category_id = action.payload.value.category.parent ? action.payload.value.category.parent.id : action.payload.value.category.id;
                state.item.sub_category_id = action.payload.value.category.id;
            }
            state.details = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.JOB_INPUT_CHANGE:
            if (action.payload.type === 'requirements' || action.payload.type === 'descriptions') {
                state.item[action.payload.type][action.payload.props] = action.payload.value;
            } else {
                state.item[action.payload.props] = action.payload.value;
            }
            if (action.payload.props === 'negotiable' && action.payload.value === true) {
                state.item['salary'] = '';
                state.item['salary_to'] = '';
            }
            if (action.payload.props === 'post_label' && action.payload.value === '1') {
                state.item['experience_year'] = "0";
            }
            // if(action.payload.props==='salary' || action.payload.props==='salary_to'){
            //
            // }

            return {
                ...state
            };
        case ActionTypes.JOB_GENERAL_STATE:
            if (!action.payload.deep) {
                state[action.payload.props] = action.payload.value;
            } else {
                state[action.payload.props][action.payload.deep] = action.payload.value;
            }
            return {
                ...state
            };
        case ActionTypes.JOB_REQUIREMENT_INCREMENT:
            //add if there is not a single item in requirements array
            if (!state.item[action.payload.type].length) {
                state.item.requirements.push('');
            }
            //if there are items in requirement check if the last item was populated.
            if (state.item[action.payload.type].length && state.item[action.payload.type][state.item[action.payload.type].length - 1].length > 0) {
                state.item[action.payload.type].push('');
            }
            return {
                ...state
            };
        case ActionTypes.JOB_FILTER:
            if (action.payload.props === 'sub_category') {
                state.selectedHits = action.payload.hits;
            }
            state.filter[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.JOB_APPLY_STATE_CHANGE:
            state.apply[action.payload.props] = action.payload.value;

            if (action.payload.jobId) {
                state.data.map((item, key) => {
                    if (action.payload.jobId === item.id) {
                        state.data[key].is_applied_public = true;
                    }
                });
            }
            if (action.payload.applied === true) {
                state.details.is_applied = true;
                state.jobDetailPage.is_applied = true;
            }
            return {
                ...state
            };
        case ActionTypes.JOB_STATE_CLEAR:
            state.item = {
                id: '',
                category_id: '',
                sub_category_id: '',
                title: '',
                availability: null,
                post_label: null,
                salary: '',
                salary_to: '',
                negotiable: false,
                experience_year: null,
                job_location: '',
                education: '',
                openings: '',
                vacancy_deadline: '',
                preferred_gender: null,
                descriptions: [''],
                requirements: [''],
                is_update: false
            };
            state.activeTabKey = '1';
            return {
                ...state
            };
        case ActionTypes.JOB_RENEW_SELECTION:
            state.myPosts.map(function (item) {
                if (action.payload.id === item.id) {
                    state.renewJob.item = item;
                    if (state.renewJob.item.applicants_count_single < 1) {
                        state.renewJob.renewalType = 'extend';
                    } else {
                        state.renewJob.renewalType = '';
                    }
                }
            });
            state.renewJob.modal = true;
            return {
                ...state
            };
        case ActionTypes.JOB_RENEW_CHANGE:
            state.renewJob[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.JOB_CANDIDATE_SAVE_TYPE_UPDATE:
            let newApplicants = state.applicants.map(function (item) {
                if (action.payload.value === item.id) {
                    item.save_type = item.save_type === 1 ? 0 : 1;
                }
                return item;
            });
            state.applicants = newApplicants;
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default JobReducer;