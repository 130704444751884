import * as ActionTypes from './../ActionTypes';


const INITIAL_STATE = {
    item: {
        company: {}
    },
    //used in the details page.
    details: {},
    data: [],
    homePageCompanyList: [],
    pagination: {},
    updateCopy: {
        company: {
            id: '',
            name: '',
            domain: '',
            phone: '',
            logo: '',
            slogan: '',
            category_type: '',
            p_address: '',
            t_address: '',
            category_id: '',
            email: '',
            about: '',
            social: {},
        },
    },
    profileValidationErrors: {},

    updatingBtn: false,
    companyUpdate: false,
    deleteConfirm: {
        confirm: false,
        type: '',
        id: ''
    },
    companyDetailPage: {},
    filter: {
        category: null,
        search: '',
        address: ''
    },
    fetchingCompanyList: false,
    profileInComplete: true,
};

const CompanyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.COMPANY_STATE:
            if (!action.payload.onlyDetail) {
                state.item.company = action.payload;
                state.updateCopy.company = action.payload;
                //Check if the company profile was create completely.
                let includesItem = ['id', 'name', 'phone', 'p_address', 'category_id', 'email', 'about'];
                try {
                    includesItem.map(function (item) {
                        if (action.payload.hasOwnProperty(item) && action.payload[item] !== '') {
                            state.profileInComplete = false;
                        } else {
                            state.profileInComplete = true;
                            throw new Error('');
                        }
                    });
                } catch (e) {
                }
            } else {
                state.details = action.payload.value;
            }

            return {
                ...state
            };
        case ActionTypes.COMPANY_INPUT_CHANGE:
            state.companyUpdate = true;
            state['updateCopy']['company'][action.payload.props] = action.payload.value;

            if (state.profileValidationErrors[action.payload.props])
                delete state.profileValidationErrors[action.payload.props];

            return {
                ...state
            };
        case ActionTypes.COMPANY_GENERAL_STATE:
            if (action.payload.props === 'data' && action.payload.isInfinite === true) {
                state[action.payload.props] = [...state.data, ...action.payload.value];//action.payload.value;
            } else {
                state[action.payload.props] = action.payload.value;
            }
            return {
                ...state
            };
        case ActionTypes.COMPANY_LOGO_CLEARED:
            state.item.company.logo = 'https://rojgarisanjal.sgp1.digitaloceanspaces.com/rojal/logo/logo_placeholder.jpg';
            return {
                ...state
            };
        case ActionTypes.COMPANY_SOCIAL_INPUT_STATE:
            if (!state.updateCopy.company.social)
                state.updateCopy.company.social = {};
            state.updateCopy.company.social[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.COMPANY_ADD_OR_REMOVE_SOCIAL:
            if (state.updateCopy.company.social.hasOwnProperty(action.payload.key) && action.payload.remove) {
                delete state.updateCopy.company.social[action.payload.key];
            } else {
                state.updateCopy.company.social[action.payload.key] = '';
            }
            return {
                ...state
            };
        case ActionTypes.COMPANY_FOLLOW_SUCCESS:

            state.homePageCompanyList.map(function (item, i) {
                if (action.payload.company_id === item.id) {
                    state.homePageCompanyList[i].is_followed = action.payload.type === 1;
                }
            });
            state.data.map(function (item, i) {
                if (action.payload.company_id === item.id) {
                    state.data[i].is_followed = action.payload.type === 1;
                }
            });
            if (Object.keys(state.companyDetailPage)) {
                state.companyDetailPage.is_followed = action.payload.type === 1;
            }
            return {
                ...state
            };
        case ActionTypes.COMPANY_FILTER:
            state.filter[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default CompanyReducer;