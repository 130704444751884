import * as ActionTypes from '../ActionTypes';
import { sendGet, sendPost } from "../../service/request";



export const blogGeneralStateChange = ({ props, value, deep = '' }) => {
    return {
        type: ActionTypes.BLOG_GENERAL_STATE,
        payload: { props, value, deep }
    }
};


export const fetchAllBlog = ({ type = '', category_id = '', search = '', page = 1, limit = 6, withCategory = false }) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendGet('/blog', { type, category_id, search, page, limit, withCategory }).then(res => {
                const { data } = res.data;
                dispatch(blogGeneralStateChange({ props: 'data', value: data }));
                dispatch(blogGeneralStateChange({ props: 'pagination', value: res.data.pagination }));
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        })
    }
}

export const fetchBlogCategories = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendGet('/blog/category').then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        })
    }
}

export const fetchSingleBlog = ({ id }) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendGet(`/blog/${id}`).then(res => {
                dispatch(blogGeneralStateChange({ props: 'item', value: res.data.data }));
                dispatch(blogGeneralStateChange({ props: 'relatedBlogs', value: res.data.related }));
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        })
    }
}

