import * as ActionTypes from './../ActionTypes';


const INITIAL_STATE = {
    payload: {
        rating: 0,
        subject: '',
        feed_type: '',
        email: '',
        description: ''
    },
    saving: false,
    feedbackModal: false,
    feedbackType: ''
};

const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ActionTypes.FEEDBACK_GENERAL_STATE:
            if (!action.payload.modal) {
                state[action.payload.props] = action.payload.value;
            } else {
                state[action.payload.modal][action.payload.props] = action.payload.value;
            }
            return {
                ...state
            };
        default: {
            return {
                ...state
            }
        }
    }
};
export default AuthReducer;
