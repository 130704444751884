import axios from "axios";
import { getCookie } from "./session";
import { openNotificationWithIcon } from "../utils/notification";
import { generalStateChange } from "../store/actions";
import { getOrCreateStore } from "../utils/withredux";
import { redirect } from "../utils/redirect";

const https = require('https');

// const apiURL = process.env.DEVELOPER === 'selvesan' ? 'http://dev.evtadmin.com/api' : 'http://evtadmin.com:8080/api';
const apiURL = process.env.APIPATH;

axios.defaults.baseURL = apiURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.data) {
        switch (error.response.status) {
            case 400:
                const reduxStore = getOrCreateStore();
                reduxStore.dispatch(generalStateChange({ props: 'validationErrors', value: error.response.data.errors }));
                openNotificationWithIcon('error', {
                    title: 'Data Validation Failed',
                    description: error.response.data.msg
                });
                break;
            case 401:
                break;
        }
    }
    return Promise.reject(error);
});

export const sendPost = (url, data, params = {}, tokenCheck = false, ctx = {}, fileUpload = false) => {
    let token = '';
    // if (tokenCheck === true) {
    token = getCookie('rs_au', ctx.req);
    // }
    return new Promise((resolve, reject) => {
        // if (tokenCheck && !token) return reject({internal_error: 'Access Token is missing!'});

        let requestObject = {
            method: 'POST',
            url,
            data,
            params,
            httpsAgent: new https.Agent({ rejectUnauthorized: false })
        };

        if (token) {
            requestObject.headers = {
                Authorization: `${token}`
            };
        }

        if (fileUpload) {
            requestObject.headers['Content-Type'] = 'multipart/form-data';
        }
        return resolve(axios(requestObject));
    });
};


export const sendGet = (url, params, tokenCheck = false, ctx = {}) => {
    let token = '';
    //    if (tokenCheck === true) {
    token = getCookie('rs_au', ctx.req);
    //   }
    return new Promise((resolve, reject) => {
        // if (tokenCheck && !token) return reject({internal_error: 'Access Token is missing!'});
        let requestObject = {
            method: "GET",
            url,
            params,
            httpsAgent: new https.Agent({ rejectUnauthorized: false })
        };
        if (token) {
            requestObject.headers = {
                Authorization: `${token}`
            };
        }
        return resolve(axios(requestObject));
    });
};


export const sendPut = (url, data, params = {}, tokenCheck = false, ctx = {}, fileUpload = false) => {
    // let token = '';

    // if (tokenCheck === true) {
    let token = getCookie('rs_au', ctx.req);
    // }
    return new Promise((resolve, reject) => {
        // if (tokenCheck && !token) return reject({ internal_error: 'Access Token is missing!' });

        let requestObject = {
            method: 'PUT',
            url,
            data,
            params,
            httpsAgent: new https.Agent({ rejectUnauthorized: false })
        };

        if (token) {
            requestObject.headers = {
                Authorization: `${token}`
            };
        }

        if (fileUpload) {
            let params = new FormData();
            Object.keys(data).forEach(item => {
                if (typeof data[item] === 'object' && data[item] !== null) {
                    params.set(item, data[item]);
                    if (item === 'files') {
                        for (let i = 0;i < data[item].length;i++) {
                            params.append(`${item}[${i}]`, data[item][i]);
                        }
                        params.append(`${item}[]`, JSON.stringify(data[item]));
                    }
                    else params.append(item, JSON.stringify(data[item]));
                } else {
                    params.append(item, data[item]);
                }
            });
            requestObject.data = params;
        }
        return resolve(axios(requestObject));
    });
};


export const sendDelete = (url, data, params = {}, tokenCheck = false, ctx = {}) => {
    let token = getCookie('rs_au', ctx.req);
    return new Promise((resolve, reject) => {
        // if (tokenCheck && !token) return reject({internal_error: 'Access Token is missing!'});
        let requestObject = {
            method: "DELETE",
            url,
            data,
            params,
            httpsAgent: new https.Agent({ rejectUnauthorized: false })
        };
        if (token) {
            requestObject.headers = {
                Authorization: `${token}`
            };
        }
        return resolve(axios(requestObject));
    });
};
