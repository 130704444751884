import { combineReducers } from 'redux';
import AppReducer from './AppReducer';
import AuthReducer from './AuthReducer';
import UserReducer from './UserReducer';
import ResumeReducer from './ResumeReducer';
import CompanyReducer from './CompanyReducer';
import JobReducer from './JobReducer';
import CategoryReducer from './CategoryReducer';
import ReportReducer from './ReportReducer';
import CandidateReducer from './CandidateReducer';
import FeedbackReducer from './FeedbackReducer';
import EmailReducer from './EmailReducer';
import FavouriteReducer from './FavouriteReducer';
import GroupListReducer from './GroupListReducer';
import BlogReducer from './BlogReducer';


export default combineReducers({
    app: AppReducer,
    auth: AuthReducer,
    user: UserReducer,
    resume: ResumeReducer,
    company: CompanyReducer,
    job: JobReducer,
    category: CategoryReducer,
    report: ReportReducer,
    candidate: CandidateReducer,
    feedback: FeedbackReducer,
    email: EmailReducer,
    favourite: FavouriteReducer,
    group: GroupListReducer,
    blog: BlogReducer
});
