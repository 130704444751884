import { sendPost } from "../../../src/service/request";
import { openNotificationWithIcon } from "../../utils/notification";
import { JOB_CANDIDATE_SAVE_TYPE_UPDATE } from "../ActionTypes";
import * as ActionTypes from "../ActionTypes";
import { Modal } from "antd";
import { redirect } from "../../utils/redirect";

const { confirm } = Modal;

export const shortListCandidate = (applicantId, selectedDeadLineId) => {
    return (dispatch, getState) => {
        let { jobStateApplicant, applicantStatsShortlisted } = getState().job;
        dispatch({ type: JOB_CANDIDATE_SAVE_TYPE_UPDATE, payload: { value: applicantId } });


        return sendPost('/applicant/shortlist', {
            applicantId,
            job_id: jobStateApplicant.id,
            selectedDeadLineId
        }, {}, true).then(({ data }) => {
            openNotificationWithIcon('success', {
                title: data.msg,
            });

            if (data.type === 1)
                applicantStatsShortlisted++;
            else
                applicantStatsShortlisted--;


            dispatch({
                type: ActionTypes.JOB_GENERAL_STATE,
                payload: { props: 'applicantStatsShortlisted', value: applicantStatsShortlisted }
            });
        }).catch(err => {
        })
    }
};
