import { redirect } from "./redirect";
import { getCookie, removeCookie } from "./session";
import cookie from "js-cookie";




export const getPassportToken = ctx => {
    return getCookie("rs_au", ctx.req);
};

export const isAuthenticated = ctx => !!getPassportToken(ctx);


export const redirectIfAuthenticated = ctx => {
    if (isAuthenticated(ctx)) {
        redirect("/user", ctx);
        return true;
    }
    return false;
};


export const redirectIfNotAuthenticated = ctx => {
    if (!isAuthenticated(ctx)) {
        redirect("/auth/login", ctx);
        return true;
    }
    return false;
};

export const signOut = (ctx = {}) => {
    if (process.browser) {
        removeCookie("et_au");
        redirect("/user/signin", ctx);
    }
};

export const checkAuthenticatedClient = () => {
    return (!!cookie.get('rs_au'));
};