import * as ActionTypes from './../ActionTypes';
import { profileCompletePercent } from "../../utils/helpers";


const INITIAL_STATE = {
    profilePercentage: 0,
    item: {
        personal: {},
        resume_info: {},
        academics: [],
        experiences: [],
        category: []
    },
    downloadResumeInfo: {
        personal: {},
        resume_info: {},
        academics: [],
        experiences: [],
        category: []
    },
    downloadResumeModal: false,
    downloadResumeSample: false,
    data: [],
    updateCopy: {
        personal: {
            id: '',
            fname: '',
            category_id: [],
            keywords: [],
            sub_category_id: '',
            mname: '',
            lname: '',
            full_name: '',
            gender: '',
            father_name: '',
            experience_year: '',
            share_contact: false,
            dob: '',
            nationality: '',
            email: '',
            secondary_email: '',
            phone: '',
            expertise_title: '',
            p_address: '',
            t_address: '',
            marital_status: ''
        },
        resume_info: {
            id: '',
            // resume_email: '',
            career_objectives: '',
            declaration: '',
            special_qualifications: [],
            languages: [{
                item: 'NE',
                value: 0
            }],
            minimum_salary: '',
            availability: '',
            // marital_status: ''
        },
        academics: [{
            id: '',
            degree: '',
            institute_name: '',
            from_year: '',
            faculty: '',
            to_year: '',
            currently_studying: false,
            gpa: '',
            desc: ''
        }],
        experiences: [{
            id: '',
            category_id: '',
            // job_title: '',
            company_name: '',
            from_date: '',
            to_date: '',
            currently_working: false,
            description: ''
        }],
        category: []
    },
    fieldsTouched: false,
    updatingBtn: false,
    personalUpdate: false,
    personalLoading: false,
    otherInfoUpdate: false,
    otherInfoLoading: false,
    academicUpdate: false,
    academicLoading: false,
    experienceUpdate: false,
    experienceLoading: false,
    deleteConfirm: {
        confirm: false,
        type: '',
        id: ''
    },
    resumeValidationErrors: {},
    resumeAcademicValidationErrors: [],
    resumeExperienceValidationErrors: [],
};

const ResumeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.RESUME_SINGLE_STATE:
            if (!action.payload.personal.secondary_email)
                action.payload.personal.secondary_email = action.payload.personal.email;

            state.item.personal = action.payload.personal;
            if (state.item.personal.share_contact === null) {
                state.item.personal.share_contact = true;
            }
            state.updateCopy.personal = action.payload.personal;

            state.item.academics = action.payload.academics;
            state.updateCopy.academics = action.payload.academics;
            state.item.experiences = action.payload.experiences;
            state.item.category = action.payload.category;
            state.updateCopy.experiences = action.payload.experiences;
            //if the response has resume_info
            if (Object.keys(action.payload.resume_info).length) {
                state.updateCopy.resume_info = action.payload.resume_info;
                state.item.resume_info = action.payload.resume_info;
            }

            if (!state.updateCopy.resume_info.languages.length) {
                state.updateCopy.resume_info.languages = [{
                    item: 'NE',
                    value: 0
                }];
            }

            if (state.updateCopy.resume_info.special_qualifications.length < 1) {
                state.updateCopy.resume_info.special_qualifications.push({ 0: '' });
            }

            //if use has not set academics information set one default for the form
            if (!state.updateCopy.academics.length) {
                state.updateCopy.academics = [];
                state.updateCopy.academics.push({
                    id: '',
                    degree: '',
                    institute_name: '',
                    from_year: '',
                    to_year: '',
                    gpa: '',
                    desc: '',
                    currently_studying: false
                });
            }

            //if use has not set experiences information set one default for the form
            if (!state.updateCopy.experiences.length) {
                state.updateCopy.experiences = [];
                state.updateCopy.experiences.push({
                    id: '',
                    category_id: '',
                    // job_title: '',
                    company_name: '',
                    from_date: '',
                    to_date: '',
                    currently_working: false
                });
            }

            state.updateCopy.category = action.payload.category;
            if (action.payload.category) {
                state.updateCopy.personal.category_id = action.payload.category.map(function (cat) {
                    return cat.id;
                });
                // state.updateCopy.category.child_id = action.payload.category.id;
                // state.updateCopy.category.parent_id = action.payload.category.parent ? action.payload.category.parent.id : null;
                // state.updateCopy.personal.category_id = action.payload.category.id;

                // state.updateCopy.personal.category_id = action.payload.category.parent ? action.payload.category.parent.id : action.payload.category.id;
                // state.updateCopy.personal.sub_category_id = action.payload.category.parent ? action.payload.category.id : '';
            }

            let profilePercentage = profileCompletePercent(state.item);
            state.profilePercentage = profilePercentage;
            return {
                ...state
            };


        case ActionTypes.RESUME_INPUT_CHANGE:
            if (state.resumeValidationErrors[action.payload.props])
                delete state.resumeValidationErrors[action.payload.props];


            switch (action.payload.type) {
                case 'category':
                    state.personalUpdate = true;
                    if (state.updateCopy.personal.category_id.indexOf(action.payload.value) > -1) {
                        state.updateCopy.personal.category_id.splice(state.updateCopy.personal.category_id.indexOf(action.payload.value), 1);
                    } else {
                        state.updateCopy.personal.category_id.push(action.payload.value);
                    }
                    break;
                case 'personal':
                    state.personalUpdate = true;
                    state['updateCopy']['personal'][action.payload.props] = action.payload.value;

                    // if (action.payload.props === 'phone' && action.payload.value.length === 0) {
                    //     state.updateCopy.personal.share_contact=false
                    // }
                    break;
                case 'resume_info':
                    state.otherInfoUpdate = true;
                    state['updateCopy']['resume_info'][action.payload.props] = action.payload.value;
                    break;
                case 'language':
                    state.otherInfoUpdate = true;
                    // if (state['updateCopy']['resume_info']['languages'].length < 5) {
                    state['updateCopy']['resume_info']['languages'][action.payload.index]['item'] = action.payload.props;
                    state['updateCopy']['resume_info']['languages'][action.payload.index]['value'] = action.payload.value;
                    //}
                    break;
                case 'special_quality':
                    state.otherInfoUpdate = true;
                    state['updateCopy']['resume_info']['special_qualifications'][action.payload.props][action.payload.props] = action.payload.value;
                    break;
                case 'academics':
                    if (state.resumeAcademicValidationErrors[action.payload.index] && state.resumeAcademicValidationErrors[action.payload.index][action.payload.props]) {
                        delete state.resumeAcademicValidationErrors[action.payload.index][action.payload.props];
                    }
                    state.academicUpdate = true;
                    state['updateCopy']['academics'][action.payload.index][action.payload.props] = action.payload.value;
                    break;
                case 'experiences':
                    if (state.resumeExperienceValidationErrors[action.payload.index] && state.resumeExperienceValidationErrors[action.payload.index][action.payload.props]) {
                        delete state.resumeExperienceValidationErrors[action.payload.index][action.payload.props];
                    }
                    state.experienceUpdate = true;
                    state['updateCopy']['experiences'][action.payload.index][action.payload.props] = action.payload.value;
                    break;
            }
            return {
                ...state
            };
        case ActionTypes.RESUME_UPDATE_SUCCESS:
            switch (action.value) {
                case 'personal':
                    state.personalUpdate = false;
                    break;
                case 'resume_info':
                    state.otherInfoUpdate = false;
                    break;
            }
            return {
                ...state
            };
        case ActionTypes.RESUME_GENERAL_STATE:
            state[action.payload.props] = action.payload.value;
            return {
                ...state
            };
        case ActionTypes.RESUME_SECTION_INCREMENT:
            switch (action.payload.value) {
                case 'special_quality':
                    let qualification = state.updateCopy.resume_info.special_qualifications;
                    //if all the input box are filled only let new input box
                    let insertNewQuality = true;
                    qualification.map((item, i) => {
                        if (!item[i].length) {
                            insertNewQuality = false;
                        }
                    });
                    if (insertNewQuality) {
                        state.updateCopy.resume_info.special_qualifications.push({ [qualification.length]: '' });
                    }
                    break;
                case 'academics':
                    let academics = state.updateCopy.academics;
                    let insertNewAcademics = true;
                    academics.map((item, i) => {
                        if ((item.degree === 'see' || item.degree === 'unsee')) {
                            if (!item.institute_name || !item.from_year || !item.gpa) {
                                insertNewAcademics = false;
                            }
                        } else {
                            if (!item.degree || !item.institute_name || !item.from_year || !item.faculty || (!item.to_year && !item.currently_studying) || !item.gpa) {
                                insertNewAcademics = false;
                            }
                        }
                    });
                    if (insertNewAcademics === true)
                        state.updateCopy.academics.push({
                            degree: '',
                            institute_name: '',
                            from_year: '',
                            to_year: '',
                            gpa: '',
                            desc: ''
                        });
                    break;
                case 'experience':
                    let experiences = state.updateCopy.experiences;
                    let insertNewExperience = true;
                    experiences.map((item, i) => {
                        if (!item.category_id || !item.company_name || !item.from_date) {
                            insertNewExperience = false;
                        }
                    });
                    if (insertNewExperience === true)
                        state.updateCopy.experiences.push({
                            id: '',
                            job_title: '',
                            category_id: '',
                            // company_name: '',
                            from_date: '',
                            to_date: '',
                            currently_working: false
                        });
                    break;
                case 'language':
                    let languages = state.updateCopy.resume_info.languages;
                    let insertNewLanguage = true;
                    if (languages.length < 4) {
                        languages.map(function (item) {
                            if (!item.item || !item.value)
                                insertNewLanguage = false;
                        });
                        if (insertNewLanguage)
                            state.updateCopy.resume_info.languages.push({ item: '', value: 0 });
                    }

                    break;
            }
            return {
                ...state
            };

        case ActionTypes.RESUME_SUCCESS_STATE:
            if (action.payload.props === 'academic') {
                state.item.academics = action.payload.value;
                state.updateCopy.academics = action.payload.value;
                state.academicUpdate = false;
                state.resumeAcademicValidationErrors = [];
                //if use has not set academics information set one default for the form
                if (!state.updateCopy.academics.length) {
                    state.updateCopy.academics = [];
                    state.updateCopy.academics.push({
                        id: '',
                        degree: '',
                        institute_name: '',
                        from_year: '',
                        to_year: '',
                        gpa: '',
                        desc: ''
                    });
                }
            } else if (action.payload.props === 'experience') {
                state.item.experiences = action.payload.value;
                state.updateCopy.experiences = action.payload.value;
                state.experienceUpdate = false;
                if (!state.updateCopy.experiences.length) {
                    state.updateCopy.experiences = [];
                    state.updateCopy.experiences.push({
                        id: '',
                        // job_title: '',
                        category_id: '',
                        company_name: '',
                        from_date: '',
                        to_date: '',
                        currently_working: false
                    });
                }
            } else if (action.payload.props === 'resume_info') {
                state.updateCopy.resume_info = action.payload.value;
                state.item.resume_info = action.payload.value;

                if (state.updateCopy.resume_info.special_qualifications.length < 1) {
                    state.updateCopy.resume_info.special_qualifications.push({ 0: '' });
                }

            }
            return {
                ...state
            };
        case ActionTypes.RESUME_DELETE_STATE:
            if (action.payload.props === 'experience') {
                state.deleteConfirm.id = action.payload.id;
                state.deleteConfirm.confirm = true;
                state.deleteConfirm.type = action.payload.props;
            }
            return {
                ...state
            };
        case ActionTypes.RESUME_CANCEL_SECTION:
            switch (action.payload.props) {
                //here action.payload.id is index number
                case 'academic':
                    state.updateCopy.academics.splice(action.payload.id, 1);
                    break;
                case 'experience':
                    state.updateCopy.experiences.splice(action.payload.id, 1);
                    break;
                case 'language':
                    state.otherInfoUpdate = true;
                    state.updateCopy.resume_info.languages.splice(action.payload.id, 1);
                    break;
            }
            return {
                ...state
            };
        case ActionTypes.RESUME_DOWNLOAD_INFO:
            state.downloadResumeInfo = action.payload.resume_info;
            return {
                ...state
            };
        case ActionTypes.RESUME_DOWNLOAD_SAMPLE:
            state.downloadResumeSample = action.payload.value;
            state.downloadResumeModal = action.payload.value;
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default ResumeReducer;