import * as ActionTypes from './../ActionTypes';
import { sendGet, sendPut } from "../../service/request";
import { openNotificationWithIcon } from "../../utils/notification";
import { authenticatedUser } from "./UserActions";
import { isAuthenticated } from "../../utils/auth";
import { isBasicInfoComplete } from "../../utils/helpers";

export const companyInputChange = ({ props, value }) => {
    return {
        type: ActionTypes.COMPANY_INPUT_CHANGE,
        payload: { props, value }
    }
};


export const submitCompanyInfo = () => {
    return (dispatch, getState) => {
        const { updateCopy } = getState().company;
        dispatch({ type: ActionTypes.COMPANY_GENERAL_STATE, payload: { props: 'updatingBtn', value: true } });
        sendPut('/company', updateCopy.company, {}, true).then(({ data }) => {
            if (data.status === true) {
                openNotificationWithIcon('success', {
                    title: 'Company update',
                    description: 'Company information were updated successfully'
                });
                dispatch(authenticatedUser());
            }
            dispatch({ type: ActionTypes.COMPANY_GENERAL_STATE, payload: { props: 'companyUpdate', value: false } });
        }).catch((err) => {
            dispatch({
                type: ActionTypes.COMPANY_GENERAL_STATE,
                payload: { props: 'profileValidationErrors', value: err.response.data.errors }
            });
        }).finally(() => {
            dispatch({ type: ActionTypes.COMPANY_GENERAL_STATE, payload: { props: 'updatingBtn', value: false } });
        });
    }
};


export const socialUpdate = (socialPayload) => {
    return (dispatch, getState) => {
        const { social } = getState().company.updateCopy.company;
        return sendPut('/company/social', socialPayload, {}, true).then(res => {
            openNotificationWithIcon('success', {
                title: 'Social updated',
                description: 'Your social accounts have been update successfully.'
            });
            dispatch(authenticatedUser());
        });
    }
};


export const followingCompanies = (qry = {}) => {
    return () => {
        return new Promise((resolve, reject) => {
            return sendGet('/company/following', { ...qry }, true, {}).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err);
            });
        });
    }
};


export const companySocialAddOrRemove = (props, remove = true) => {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.COMPANY_ADD_OR_REMOVE_SOCIAL,
            payload: { key: props, remove }
        });
    }
};

export const socialInputChange = ({ props, value }) => {
    return {
        type: ActionTypes.COMPANY_SOCIAL_INPUT_STATE,
        payload: { props, value }
    }
};

/**
 *
 * @param props
 * @param value
 * @param searchNow
 * @return {Function}
 */
export const companyFilterInputChange = ({ props, value, searchNow = true }) => {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.COMPANY_FILTER,
            payload: { props, value }
        });

        if (searchNow === true)
            dispatch(getAllCompanies(getState().company.filter));
    };
};

/**
 *
 * @param filter
 * @param ctx
 * @param isInfinite
 * @param isForHomePage
 * @return {function(*): Promise<any>}
 */
export const getAllCompanies = (filter = {}, ctx, isInfinite = false, isForHomePage = false) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.COMPANY_GENERAL_STATE, payload: { props: 'fetchingCompanyList', value: true } });
        return sendGet('/company', filter, true, ctx).then(({ data }) => {
            if (isForHomePage) {
                dispatch({
                    type: ActionTypes.COMPANY_GENERAL_STATE,
                    payload: { props: 'homePageCompanyList', value: data.data, pagination: data.pagination, isInfinite }
                });

            } else {
                dispatch({
                    type: ActionTypes.COMPANY_GENERAL_STATE,
                    payload: { props: 'data', value: data.data, pagination: data.pagination, isInfinite }
                });

            }

            dispatch({
                type: ActionTypes.COMPANY_GENERAL_STATE,
                payload: { props: 'pagination', value: data.pagination }
            })
        }).catch(err => {
        }).finally(() => {
            dispatch({ type: ActionTypes.COMPANY_GENERAL_STATE, payload: { props: 'fetchingCompanyList', value: false } });
        })
    }
};


export const followCompany = ({ company_id, type = 1 }) => {
    return (dispatch, getState) => {
        if (isAuthenticated({})) {
            const { data } = getState().user;
            if (isBasicInfoComplete(data)) {
                dispatch({ type: ActionTypes.COMPANY_FOLLOW_SUCCESS, payload: { type, company_id } });
                return new Promise((resolve, reject) => {
                    return sendPut('/company/follow', { type, company_id }, {}, true).then((res) => {
                        dispatch({ type: ActionTypes.COMPANY_FOLLOW_SUCCESS, payload: { type, company_id } });
                        resolve(res)
                    }).catch(err => {
                        reject(err);
                        dispatch({
                            type: ActionTypes.COMPANY_FOLLOW_SUCCESS,
                            payload: { type: type === 1 ? 0 : 1, company_id }
                        });
                    });
                });
            }
        } else {
            dispatch({ type: ActionTypes.AUTH_MODEL_SWITCH, payload: true });
        }
    }
};


export const fetchCompanyDetailsById = (ctx, id, isUpdate = false, isForDetailsPage = false) => {
    return (dispatch) => {
        return sendGet(`/company/${id}/details`, {}, true, ctx).then(({ data }) => {
            if (isForDetailsPage)
                dispatch({
                    type: ActionTypes.COMPANY_GENERAL_STATE,
                    payload: { value: data.data, props: 'companyDetailPage' }
                });
            else
                dispatch({ type: ActionTypes.COMPANY_STATE, payload: { value: data.data, onlyDetail: true, isUpdate } });
        }).catch(err => {
        })
    }
};


export const fetchRelatedCompanies = ({ companyId, categoryId }) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sendGet(`/company/related`, { companyId, categoryId }).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }
}
