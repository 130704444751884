export const APP_COUNTER = 'APP_COUNTER';
export const APP_GENERAL_STATE = 'APP_GENERAL_STATE';
export const TICK = 'TICK';


//Authentication
export const AUTH_INPUT_CHANGE = 'AUTH_INPUT_CHANGE';
export const AUTH_SUCCESSFUL = 'AUTH_SUCCESSFUL';
export const AUTH_LOADER = 'AUTH_LOADER';
export const AUTH_V_ERRORS = 'AUTH_V_ERRORS';
export const AUTH_RESET_LINK_SUCCESS = 'AUTH_RESET_LINK_SUCCESS';
export const AUTH_VERIFICATION_STATE = 'AUTH_VERIFICATION_STATE';
export const AUTH_STATE_RESET = 'AUTH_STATE_RESET';
export const AUTH_MODEL_SWITCH = 'AUTH_MODEL_SWITCH';
export const AUTH_SCREEN_SWITCH = 'AUTH_SCREEN_SWITCH';
export const AUTH_GENERAL_STATE = 'AUTH_GENERAL_STATE';
export const AUTH_INCORRECT_STATE_CHANGE = 'AUTH_INCORRECT_STATE_CHANGE';


//User
export const USER_STATE = 'USER_STATE';
export const USER_STATE_LOGOUT = 'USER_STATE_LOGOUT';
export const USER_INPUT_CHANGE = 'USER_INPUT_CHANGE';
export const USER_SOCIAL_INPUT_STATE = 'USER_SOCIAL_INPUT_STATE';
export const USER_ADD_OR_REMOVE_SOCIAL = 'USER_ADD_OR_REMOVE_SOCIAL';
export const USER_GENERATE_STATE = 'USER_GENERATE_STATE';
export const USER_FETCH_ALL = 'USER_FETCH_ALL';
export const USER_RESUME_PUBLISH = 'USER_RESUME_PUBLISH';
export const USER_FILTER = 'USER_FILTER';
export const USER_LOGO_CLEARED = 'USER_LOGO_CLEARED';
export const USER_SETTINGS = 'USER_SETTINGS';


//Resume
export const RESUME_SINGLE_STATE = 'RESUME_SINGLE_STATE';
export const RESUME_FETCH_ALL = 'RESUME_ALL_STATE';
export const RESUME_INPUT_CHANGE = 'RESUME_INPUT_CHANGE';
export const RESUME_UPDATE_SUCCESS = 'RESUME_UPDATE_SUCCESS';
export const RESUME_GENERAL_STATE = 'RESUME_GENERAL_STATE';
export const RESUME_SECTION_INCREMENT = 'RESUME_SECTION_INCREMENT';
export const RESUME_SUCCESS_STATE = 'RESUME_ACADEMIC_STATE';
export const RESUME_DELETE_STATE = 'RESUME_DELETE_STATE';
export const RESUME_CANCEL_SECTION = 'RESUME_CANCEL_SECTION';
export const RESUME_DOWNLOAD_INFO = 'RESUME_DOWNLOAD_INFO';
export const RESUME_DOWNLOAD_SAMPLE = 'RESUME_DOWNLOAD_SAMPLE';


//Job
export const JOB_INPUT_CHANGE = 'JOB_INPUT_CHANGE';
export const JOB_GENERAL_STATE = 'JOB_GENERAL_STATE';
export const JOB_STATE = 'JOB_STATE';
export const JOB_REQUIREMENT_INCREMENT = 'JOB_REQUIREMENT_INCREMENT';
export const JOB_FILTER = 'JOB_FILTER';
export const JOB_STATE_CLEAR = 'JOB_STATE_CLEAR';
export const JOB_APPLY_STATE_CHANGE = 'JOB_APPLY_STATE_CHANGE';
export const JOB_RENEW_SELECTION = 'JOB_RENEW_SELECTION';
export const JOB_RENEW_CHANGE = 'JOB_RENEW_CHANGE';
export const JOB_CANDIDATE_SAVE_TYPE_UPDATE = 'JOB_CANDIDATE_SAVE_TYPE_UPDATE';

//Company
export const COMPANY_STATE = 'COMPANY_STATE';
export const COMPANY_INPUT_CHANGE = 'COMPANY_INPUT_CHANGE';
export const COMPANY_GENERAL_STATE = 'COMPANY_GENERAL_STATE';
export const COMPANY_SOCIAL_INPUT_STATE = 'COMPANY_SOCIAL_INPUT_STATE';
export const COMPANY_ADD_OR_REMOVE_SOCIAL = 'COMPANY_ADD_OR_REMOVE_SOCIAL';
export const COMPANY_LOGO_CLEARED = 'COMPANY_LOGO_CLEARED';
export const COMPANY_FILTER = 'COMPANY_FILTER';
export const COMPANY_FOLLOW_SUCCESS = 'COMPANY_FOLLOW_SUCCESS';


//Category
export const CATEGORY_ALL_STATE = 'CATEGORY_ALL_STATE';


//Report
export const FETCH_REPORT_DASHBOARD = 'FETCH_REPORT_DASHBOARD';


//Candidates
export const CANDIDATE_GENERAL_STATE = 'CANDIDATE_GENERAL_STATE';

//Feedback
export const FEEDBACK_GENERAL_STATE = 'FEEDBACK_GENERAL_STATE';
export const FEEDBACK_RESET_STATE = 'FEEDBACK_RESET_STATE';


//Feedback
export const EMAIL_GENERAL_STATE = 'EMAIL_GENERAL_STATE';

//Feedback
export const FAVOURITE_GENERAL_STATE = 'FAVOURITE_GENERAL_STATE';

// Group List
export const GROUP_LIST_GENERAL_STATE = 'GROUP_LIST_GENERAL_STATE';

// Group List
export const BLOG_GENERAL_STATE = 'BLOG_GENERAL_STATE';
