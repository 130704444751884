import * as ActionTypes from './../ActionTypes';
import {sendDelete, sendGet, sendPost, sendPut} from "../../service/request";
import {openNotificationWithIcon} from "../../utils/notification";
import Router from "next/router";


export const groupListGeneralStateChange = ({props, value, deep = ''}) => {
    return {
        type: ActionTypes.GROUP_LIST_GENERAL_STATE,
        payload: {props, value, deep}
    }
};


export const saveGroupAction = () => {
    return (dispatch, getState) => {
        const {payload} = getState().group;
        return new Promise((resolve, reject) => {
            return sendPost('/group', payload, {}, true).then(({data}) => {
                openNotificationWithIcon('success', {
                    title: 'Success',
                    description: "New group was added successfully"
                });
                dispatch(fetchGroupListAction());
                resolve(data);
            }).catch(err => {
                reject(err);
            }).finally(() => {
                dispatch({type: ActionTypes.JOB_GENERAL_STATE, payload: {props: 'publishing', value: false}});
            });

        })

    }
};


export const updateGroupAction = (groupName, id) => {
    return (dispatch, getState) => {
        const {payload} = getState().group;
        return new Promise((resolve, reject) => {
            return sendPut('/group/' + id, {group_name: groupName}, {}, true).then(({data}) => {
                openNotificationWithIcon('success', {
                    title: 'Success',
                    description: "Group name updated successfully"
                });
                dispatch(fetchGroupListAction());
                resolve(data);
            }).catch(err => {
                reject(err);
            }).finally(() => {
                dispatch({type: ActionTypes.JOB_GENERAL_STATE, payload: {props: 'publishing', value: false}});
            });

        })

    }
};


export const fetchGroupListAction = (ctx) => {
    return (dispatch, getState) => {
        const {payload} = getState().group;
        return new Promise((resolve, reject) => {
            return sendGet('/group', {}, true, ctx).then(({data}) => {
                dispatch(groupListGeneralStateChange({props: 'groupLists', value: data.data}));
                resolve(data);
            }).catch(err => {
                console.log(err);
                reject(err);
            }).finally(() => {
            });

        })

    }
};

export const deleteGroupListAction = (id) => {
    return (dispatch, getState) => {
        const {groupLists} = getState().group;
        return new Promise((resolve, reject) => {
            return sendDelete(`/group/${id}`, {}, true).then(({data}) => {
                const newGroupLists = groupLists.filter(item => item.id !== id);
                dispatch(groupListGeneralStateChange({props: 'groupLists', value: newGroupLists}));
                resolve(data);
            }).catch(err => {
                console.log(err);
                reject(err);
            }).finally(() => {
            });
        })

    }
};

