import * as ActionTypes from './../ActionTypes';


const INITIAL_STATE = {
    dashboard: {
        resume_view_count: 0,
        application_submit_count: 0,
    }
};

const ReportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_REPORT_DASHBOARD:
            state.dashboard = action.payload.value;
            return {
                ...state
            };
        default: {
            return state;
        }
    }
};
export default ReportReducer;